import {
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  BlockStack,
} from "@shopify/polaris";
import { Table } from "antd";
import "./SkeltonBulkEditGrid.css";
import { getParams } from "../helper";

const SkeltonBulkEditGrid = (props: any) => {

  let isVariantGrid = getParams();


  const result: any[] = [
    {
      title: (
        <div className="checkbox-skelton">
          <SkeletonBodyText lines={1} />
        </div>
      ),
      dataIndex: "skelton",
      render: (t: any) => {
        return (
          <div className="checkbox-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        );
      },
      onCell: (_: any, index: any) => ({
        colSpan: index === 0 ? 0 : null,
      }),
      width: "20px",
      visible: true,
      fixed: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "ProductDetails",
      render: (t: any) => {
        if (t === undefined) {
          return (
            <div className="selection_desc-skelton">
              <SkeletonBodyText lines={1} />
            </div>
          );
        } else return t;
      },
      onCell: (_: any, index: any) => ({
        colSpan: index === 0 ? 3 : 1,
      }),
      width: "320px",
      visible: true,
      fixed: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "sku",
      render: (t: any) => t,
      width: "225px",
      visible: true,
      fixed: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "productTitle",
      render: (t: any) => <div>{t}</div>,
      width: "225px",
      visible: true,
      fixed: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "description",
      render: (t: any) => t,
      width: "110px",
      visible: true,
      fixed: true,
      _field_data: {
        label: "description",
      },
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "ProductDetails1",
      width: "300px",
      render: (t: any) => t,
      visible: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "ProductDetails2",
      width: "300px",
      render: (t: any) => t,
      visible: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "ProductDetails3",
      width: "300px",
      render: (t: any) => t,
      visible: true,
    },
    {
      title: (
        <div className="product-title__skelton">
          <SkeletonDisplayText size="small" />
        </div>
      ),
      dataIndex: "ProductDetails4",
      width: "300px",
      render: (t: any) => t,
      visible: true,
    },
  ];
  function rowsRenderer() {
    let tempRowRenderer: any = [];
    [1, 2, 3, 4, 5].forEach((_elem: any, index: number) => {
      tempRowRenderer.push({
        key: index,
        ProductDetails: (
          <InlineStack gap={"200"} wrap={false} blockAlign="start" align="start">
            <div className="thumbnail-skelton">
              <SkeletonThumbnail size="small" />
            </div>
            <BlockStack gap={"100"} align="start">
              <div className="product-name-skelton">
                <SkeletonDisplayText size="small" />
              </div>
              {!isVariantGrid && (
                <div className="product-variant-skelton">
                  <SkeletonDisplayText size="small" />
                </div>
              )}
            </BlockStack>
          </InlineStack>
        ),
        productTitle: (
          <div className="sku-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
        sku: (
          <div className="sku-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
        description: (
          <div className="sku-description">
            <SkeletonDisplayText size="medium" />
          </div>
        ),
        ProductDetails1: (
          <div className="col-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
        ProductDetails2: (
          <div className="col-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
        ProductDetails3: (
          <div className="col-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
        ProductDetails4: (
          <div className="col-skelton">
            <SkeletonBodyText lines={1} />
          </div>
        ),
      });
    });
    return [...tempRowRenderer];
  }
  return (
    <BlockStack gap={"200"}>
      <div className="card-header--padding">
        <InlineStack align="space-between" gap={"200"} blockAlign="center">
          <div className="selected-item-skelton">
            <SkeletonDisplayText size="small" />
          </div>
          <InlineStack gap={"300"}>
            {!isVariantGrid && <div className="category-skelton">
              <SkeletonDisplayText size="medium" />
            </div>}
            <div className="vertical--lines"></div>
            <div className="modal-btn-skelton">
              <SkeletonDisplayText size="small" />
            </div>
          </InlineStack>
        </InlineStack>
      </div>
      <div className="bulkEdit-grid--skelton">
        <Table
          rowClassName={(record, index) =>
            index === 0 ? "first__row-skelton" : ""
          }
          scroll={{ x: "max-content" }}
          pagination={false}
          columns={result}
          dataSource={[{ test: "test", key: 'skeletonTest' }, ...rowsRenderer()]}
        />
      </div>
      <InlineStack align='center' blockAlign='center' gap={"200"}>
        <div className="modal-btn-skelton">
          <SkeletonDisplayText size="small" />
        </div>
        <div className="pagination-text">
          <SkeletonDisplayText size="small" />
        </div>
        <div className="modal-btn-skelton">
          <SkeletonDisplayText size="small" />
        </div>
      </InlineStack>
    </BlockStack>
  );
};
export default SkeltonBulkEditGrid;
