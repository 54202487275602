import { ColumnBuilderProps, ExtractEditedColI, ExtractPrefilledVariantAttributesI } from "../../type";
import { _STATICCOLUMNS } from "../constants";

export function columnBuilder(props: ColumnBuilderProps) {
    const { columns, isVariantBulkEdit, rows } = props;
    let result: any[] = [..._STATICCOLUMNS];
    let manageColumns: any = {};
    let selectAllCols: any = {};
    let collapsibles: any = {}
    let index: number = 0;

    for (const categoryKey in columns) {
        const category = columns[categoryKey];
        selectAllCols[categoryKey] = {
            selectAll: extractPrefilledVariantAttributes({
                categoryKey, isVariantBulkEdit
            })
        }
        collapsibles[categoryKey] = { // to open or to close the manage col's collapsible
            isOpen: extractPrefilledVariantAttributes({
                categoryKey, isVariantBulkEdit
            })
        }
        for (const fieldKey in category) {
            index++;
            const field = category[fieldKey];
            if (categoryKey === 'Mandantory') {
                if (!isVariantBulkEdit)
                    manageColumns[categoryKey] = {
                        ...manageColumns[categoryKey],
                        [fieldKey]: {
                            visible: extractPrefilledVariantAttributes({
                                categoryKey, isVariantBulkEdit
                            }),
                            ...field,
                            type: categoryKey
                        }
                    }
            }
            else
                manageColumns[categoryKey] = {
                    ...manageColumns[categoryKey],
                    [fieldKey]: {
                        visible: extractPrefilledVariantAttributes({
                            categoryKey, isVariantBulkEdit,
                            rows: rows,
                            categoryField: fieldKey
                        }),
                        ...field,
                        type: categoryKey
                    }
                }
            result.push({
                _field_data: field,
                key: fieldKey,
                type: categoryKey,
                visible: extractPrefilledVariantAttributes({
                    categoryKey, isVariantBulkEdit,
                    rows: props.rows,
                    categoryField: fieldKey
                }),
                title: field?.label,
                dataIndex: field.label,
                width: '300px',
                isModified: false,
                hasAmazonRecommendation: field?.amazon_recommendation !== undefined,
                elementIndexKey: index + (field?.definition ?? index)
            });
        }
    }
    return { result, manageColumns, selectAllCols, collapsibles };
}

export function extractEditedCol(props: ExtractEditedColI) {
    let { colIdentifier, modifiedState, initialState } = props;
    let iterator = [...initialState?.rows ?? []];

    let modifiedCols: any[] = [];
    iterator?.forEach((state, index) => {
        for (const rowKey in state) {
            if (state[rowKey]?.displayValue !== modifiedState[index][rowKey]?.displayValue && !modifiedCols?.includes(rowKey)) {
                modifiedCols.push(rowKey)
            }
        }
    });
    return !modifiedCols?.includes(colIdentifier);
}

export function extractPrefilledVariantAttributes(_props: ExtractPrefilledVariantAttributesI) {
    let { categoryKey, isVariantBulkEdit, rows, categoryField } = _props;
    let isVisible: boolean = false
    if (!isVariantBulkEdit) {
        if (categoryKey === 'Mandantory') {
            isVisible = true;
        }
    }
    else {
        if (categoryKey === 'Basic')
            return true
        let attributes: any[] = [];
        if (rows && rows?.length > 0) {
            rows.forEach((elem: any) => {
                attributes.push(...elem?.category_settings?.attributes_mapping?.optional_attribute ?? [],
                    ...elem?.edited_variant_attributes ?? [])
            })
        }

        attributes?.forEach(elem => {
            if (elem?.amazon_attribute === categoryField) {
                isVisible = true;
            }
        })
    }
    return isVisible
}