import { ClosePopoverIfRowInSelectionProps, IsRowElementDisabledI, IsRowInMultipleSelectionProps, RowBuilderProps } from "../../type";
import { _SHOPIFYATTRIBTUES } from "../constants";
import { extractCurrentSelection, formatShopifyAttributes } from "./bulkeditorController";
import { columnBuilder } from "./columnController";

export function rowBuilder(props: RowBuilderProps) {
    const { rows, isVariantBulkEdit, columns } = props;
    let tempRows: any = [];
    rows.forEach((elem: any) => {
        let { source_product_id, title, main_image, description, editedData } = elem;
        tempRows.push({
            key: {
                value: source_product_id
            },
            source_product_id: {
                value: source_product_id
            },
            title: {
                value: title ?? 'Title ' + source_product_id
            },
            main_image: {
                value: main_image
            },
            description: {
                value: description
            },
            ...(isVariantBulkEdit ? preapreVariantRows(elem, columns, rows, isVariantBulkEdit, {
                record: {
                    ...elem
                }
            }) : dynamicCols(elem?.editedData, elem?.container_id, columns, rows, isVariantBulkEdit, {
                record: {
                    ...elem
                }
            })),
            editedData
        })
    });
    return tempRows;
}

function dynamicCols(editedData: any, p_container_id: string, columns: any, rows: any, isVariantBulkEdit: boolean, record: any) {
    const tempColumns = columnBuilder({
        columns: columns,
        isVariantBulkEdit,
        rows: rows,
        from: 'rows'
    });
    let result: any = {}
    let tempRows: any = [];
    if (editedData?.length === 1)
        tempRows.push(
            ...(editedData?.[0]?.category_settings?.attributes_mapping?.optional_attribute ?? []),
            ...(editedData?.[0]?.category_settings?.attributes_mapping?.required_attribute ?? [])
        )
    else {
        editedData?.forEach((elem: any) => {
            if (elem?.source_product_id === p_container_id) {
                tempRows.push(...(elem?.category_settings?.attributes_mapping?.optional_attribute ?? []), ...(elem?.category_settings?.attributes_mapping?.required_attribute ?? []))
            }
        })
    }
    tempColumns?.result?.forEach((elem: any) => {
        if (elem?.dataIndex !== 'ProductDetails' && elem?.dataIndex !== 'sku') {
            result = {
                ...result,
                [elem?.dataIndex]: {
                    ...formatColData(elem, tempRows, elem?._field_data ?? {}),
                    hasAmazonRecommendation: elem?.hasAmazonRecommendation,
                    options: elem?._field_data?.amazon_recommendation ?? {},
                    record: { ...record?.record ?? {} }
                },
                variantLength: editedData?.length
            }
        }
    })
    return result
}

function preapreVariantRows(variantData: any, columns: any, rows: any, isVariantBulkEdit: boolean, record: any) {
    const tempColumns = columnBuilder({
        columns: columns,
        isVariantBulkEdit,
        rows: rows,
        from: 'rows'
    });
    let result: any = {}
    let tempRows: any = [];
    if (variantData?.edited_variant_attributes)
        tempRows.push(...(variantData?.edited_variant_attributes ?? []))
    if (variantData?.category_settings?.attributes_mapping?.optional_attribute)
        tempRows.push(...(variantData?.category_settings?.attributes_mapping?.optional_attribute ?? []))

    tempColumns?.result?.forEach((elem: any) => {
        if (elem?.dataIndex !== 'ProductDetails' && elem?.dataIndex !== 'sku') {
            result = {
                ...result,
                [elem?.dataIndex]: {
                    ...formatColData(elem, tempRows, elem?._field_data ?? {}),
                    hasAmazonRecommendation: elem?.hasAmazonRecommendation,
                    options: elem?._field_data?.amazon_recommendation ?? {},
                    record: { ...record?.record ?? {} }
                },
            }
        }
    })
    return result
}

function formatColData(col: any, rows: any[], _field_data: any) {
    let data: any = undefined;
    rows.forEach(elem => {
        if (col?.key === elem?.amazon_attribute) {
            data = {
                isModified: false,
                isEditable: false,
                hasAmazonRecommendation: elem?.recommendation !== '',
                amazonRecommendation: {
                    options: _field_data?.amazon_recommendation,
                    value: elem?.recommendation
                },
                shopifySelect: {
                    options: formatShopifyAttributes(_SHOPIFYATTRIBTUES, true),
                    value: elem?.shopify_attribute ?? ''
                },
                customText: {
                    value: elem?.custom_text ?? ''
                },
                currentSelection: {
                    ...extractCurrentSelection(elem, _field_data)
                },
                columnName: col?.title,
                clicked: '',
                displayValue: extractCurrentSelection(elem, _field_data)?.value ?? ''
            }
        }
    })
    if (data === undefined)
        data = {
            isEditable: false,
            isModified: false,
            hasAmazonRecommendation: _field_data?.amazon_recommendation !== undefined,
            amazonRecommendation: {
                options: _field_data?.amazon_recommendation ?? [],
                value: ''
            },
            shopifySelect: {
                options: formatShopifyAttributes(_SHOPIFYATTRIBTUES, true),
                value: ''
            },
            customText: {
                value: ''
            },
            currentSelection: {
                type: '',
                value: '',
                options: []
            },
            columnName: col?.title,
            clicked: '',
            displayValue: ''
        }
    return data
}


/**
 * Checks if a specific row identified by the source product ID exists in the selected rows.
 *
 * @param {IsRowInMultipleSelectionProps} props - The properties for the function.
 * @param {any[]} props.selectedRowKeys - An array of selected row keys.
 * @param {string | number} props.source_product_id - The source product ID to check for in the selection.
 *
 * @returns {boolean} - `true` if the source product ID exists in the selected rows, `false` otherwise.
 *
 * @example
 * // Example Usage:
 * const selectedRows = [1, 3, 5, 7];
 * const sourceProductId = 5;
 * const isInSelection = isRowInMultipleSelection({ selectedRowKeys: selectedRows, source_product_id: sourceProductId });
 * console.log(isInSelection); // Output: true
 */
export const isRowInMultipleSelection = (props: IsRowInMultipleSelectionProps): boolean => {
    const { selectedRowKeys, source_product_id } = props;
    const set = new Set(selectedRowKeys);
    const exists = set.has(source_product_id);
    return exists;
}

/**
 * Closes the popover if the specified rows are in the selection.
 *
 * @param {ClosePopoverIfRowInSelectionProps} props - The properties for the function.
 * @param {any[]} props.rows - An array of rows to check for selection.
 * @param {string[]} props.selectedKeys - An array of selected keys used for checking selection.
 *
 * @returns {void} - This function does not return a value.
 *
 */

export const closePopoverIfRowInSelection = (props: ClosePopoverIfRowInSelectionProps): void => {
    const { rows, selectedKeys } = props;
    rows.forEach((row: any) => {
        const keyValue = row?.key?.value;
        if (selectedKeys?.includes(keyValue)) {
            for (const prop in row) {
                if (row?.hasOwnProperty(prop) && row[prop]?.hasOwnProperty('clicked')) {
                    row[prop].clicked = '';
                }
            }
        }
    });
}

export function isRowElementDisabled(_props: IsRowElementDisabledI) {
    return _props.rowSelection?.selectedRowKeys?.includes(_props.key ?? '') ?? false
}