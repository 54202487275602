import { _ACTIONS } from "./actions";

export function reducer(state: any, action: { type: string, payload: any }) {
    const updateState = (payload: any) => ({
        ...state,
        ...payload
    });

    // Since all of the actions are performing the same operations on the state a break statement was not required. Use break/return where data manupulation is required.
    switch (action.type) {
        case _ACTIONS.COLUMNS:
        case _ACTIONS.SET_ROW:
        case _ACTIONS.UPDATE_ROW:
        case _ACTIONS.SET_COMMENCED_STATE:
        case _ACTIONS.PAGINATION_CHANGED:
        case _ACTIONS.SHOPIFY_ATTRIBUTES:
        case _ACTIONS.CATEGORIES:
            return updateState(action.payload);
        default:
            return { ...state };
    }
}