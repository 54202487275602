export { }

export const rowData: any = [
    {
        "_id": "15209",
        "container_id": "8414987518184",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518184",
        "title": "Premium Wireless Headphones",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518184",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518184",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            },
            {
                "_id": {
                    "$oid": "656477b6d77ad66a387341ba"
                },
                "container_id": "8414987518184",
                "shop_id": "122",
                "source_product_id": "8414987518184",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "attributes_mapping": {
                        "optional_attribute": [
                            {
                                "amazon_attribute": "update_delete",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer",
                                "custom_text": "54654654",
                                "shopify_select": "custom",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "closure_type",
                                "custom_text": "",
                                "shopify_select": "recommendation",
                                "recommendation": "Drawstring",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            }
                        ],
                        "required_attribute": [
                            {
                                "amazon_attribute": "brand_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "part_number",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "model",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "product_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "style_name",
                                "custom_text": "",
                                "recommendation": "Diaper Bags",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "bullet_point1",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "department_name",
                                "custom_text": "",
                                "recommendation": "Boys",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "variant_title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_map",
                                "custom_text": "",
                                "recommendation": "Blue",
                                "shopify_attribute": "Size",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "material_type1",
                                "custom_text": "",
                                "recommendation": "Canvas",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_wheels",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer_contact_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_length",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_width",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_height",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name_unit_of_measure",
                                "custom_text": "",
                                "recommendation": "centiliters",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count_type",
                                "custom_text": "",
                                "recommendation": "Gram",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "country_of_origin",
                                "custom_text": "",
                                "recommendation": "United States",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_type",
                                "custom_text": "",
                                "recommendation": "Seller",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "external_product_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "maximum_retail_price",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "list_price_with_tax",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            }
                        ]
                    },
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "title": "fasffaffafsdf"
            }
        ]
    },
    {
        "_id": "15239",
        "container_id": "8414989222120",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p><em>This is a demonstration store. You can purchase products like this from <a href=\"https://www.purefixcycles.com\" target=\"_blank\">Pure Fix Cycles</a></em></p><p>All fast and no class? Not anymore! The lattice chainring from Factory 5 will turn any racer into a show bike, without sacrificing speed. Precision CNC milled from a single piece of 7075 aluminum, the innovative lattice pattern maintains strength, cuts weight, and looks like a dream.</p>\n<div class=\"textile\">\n<h3>Specs</h3>\n<ul>\n<li>BCD: 144</li>\n<li>Teeth: 49</li>\n<li>Weight: 78g</li>\n<li>Lifetime warranty via Factory 5</li>\n</ul>\n</div>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/CHAINRING_F5-LATTICE_BLACK-N-SILVER_FRONT_WEB.jpg?v=1698402013",
        "source_product_id": "8414989222120",
        "title": "NebulaGlow Starry Night Lamp",
        "editedData": [
            {
                "_id": {
                    "$oid": "658169abe815fe0141576936"
                },
                "container_id": "8414989222120",
                "shop_id": "122",
                "source_product_id": "44996338680040",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage"
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "658169abe815fe0141576976"
                },
                "container_id": "8414989222120",
                "shop_id": "122",
                "source_product_id": "44996338712808",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage"
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "658169abe815fe0141576986"
                },
                "container_id": "8414989222120",
                "shop_id": "122",
                "source_product_id": "8414989222120",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "attributes_mapping": {
                        "optional_attribute": [
                            {
                                "amazon_attribute": "model_year",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "outer_material_type1",
                                "custom_text": "fsdaf",
                                "shopify_select": "custom",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_compartments",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "meta_attribute_exist": false
                            }
                        ],
                        "required_attribute": [
                            {
                                "amazon_attribute": "brand_name",
                                "custom_text": "attributeeeeeeee",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "part_number",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "model",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "product_description",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "style_name",
                                "custom_text": "",
                                "recommendation": "Fashion Waist Packs",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "bullet_point1",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "department_name",
                                "custom_text": "",
                                "recommendation": "Girls",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "material_type1",
                                "custom_text": "",
                                "recommendation": "Fabric",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_wheels",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer_contact_information",
                                "custom_text": "attributeattribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_length",
                                "custom_text": "attributeattribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_width",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_height",
                                "custom_text": "attributeattribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name_unit_of_measure",
                                "custom_text": "",
                                "recommendation": "Quarts",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count",
                                "custom_text": "attributeattribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count_type",
                                "custom_text": "",
                                "recommendation": "Pound",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "country_of_origin",
                                "custom_text": "",
                                "recommendation": "Bahrain",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_type",
                                "custom_text": "",
                                "recommendation": "Manufacturer & Seller",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_description",
                                "custom_text": "sfasfasf",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "external_product_information",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "maximum_retail_price",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "list_price_with_tax",
                                "custom_text": "attribute",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            }
                        ]
                    },
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage"
                },
                "source_shop_id": "120"
            }
        ]
    },
    {
        "_id": "15213",
        "container_id": "8414986928360",
        "source_product_id": "44996331798760",
        "type": "simple",
        "user_id": "653a4e0c506bd44970069007",
        "description": "<p>asda</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "quantity": 0,
        "title": "Smart Home Security Camera",
        "visibility": "Catalog and Search",
        "editedData": [
            {
                "_id": {
                    "$oid": "658168bde815fe01414f8095"
                },
                "container_id": "8414986928360",
                "shop_id": "122",
                "source_product_id": "44996331798760",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "attributes_mapping": {
                        "optional_attribute": [
                            {
                                "amazon_attribute": "manufacturer",
                                "custom_text": "fs",
                                "shopify_select": "custom",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "closure_type",
                                "custom_text": "",
                                "shopify_select": "recommendation",
                                "recommendation": "Drawstring",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "outer_material_type2",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "outer_material_type4",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "inner_material_type1",
                                "custom_text": "",
                                "shopify_select": "recommendation",
                                "recommendation": "Felt",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            }
                        ],
                        "required_attribute": [
                            {
                                "amazon_attribute": "brand_name",
                                "custom_text": "dsfaf",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "part_number",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "model",
                                "custom_text": "ffsd",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "product_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "style_name",
                                "custom_text": "",
                                "recommendation": "Fashion Waist Packs",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "bullet_point1",
                                "custom_text": "sdfsa",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "department_name",
                                "custom_text": "",
                                "recommendation": "Boys",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_name",
                                "custom_text": "fe44334",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_map",
                                "custom_text": "",
                                "recommendation": "Gold",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "material_type1",
                                "custom_text": "",
                                "recommendation": "Fabric",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_wheels",
                                "custom_text": "4",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer_contact_information",
                                "custom_text": "4",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_length",
                                "custom_text": "4",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_width",
                                "custom_text": "4",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_height",
                                "custom_text": "34",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name",
                                "custom_text": "343",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name_unit_of_measure",
                                "custom_text": "",
                                "recommendation": "cubic_feet",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count",
                                "custom_text": "fsf",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count_type",
                                "custom_text": "",
                                "recommendation": "Ounce",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "country_of_origin",
                                "custom_text": "",
                                "recommendation": "Bahrain",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_type",
                                "custom_text": "",
                                "recommendation": "Manufacturer & Seller",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_description",
                                "custom_text": "fdsa",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "external_product_information",
                                "custom_text": "fdsa",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "maximum_retail_price",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "list_price_with_tax",
                                "custom_text": "afsdfasf",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "custom",
                                "meta_attribute_exist": false
                            }
                        ]
                    },
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage"
                },
                "source_shop_id": "120"
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518186",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518186",
        "title": "Fitness Tracker with Heart Rate Monitor",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518186",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518186",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            },
            {
                "_id": {
                    "$oid": "656477b6d77ad66a387341ba"
                },
                "container_id": "8414987518186",
                "shop_id": "122",
                "source_product_id": "8414987518186",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "attributes_mapping": {
                        "optional_attribute": [
                            {
                                "amazon_attribute": "update_delete",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer",
                                "custom_text": "54654654",
                                "shopify_select": "custom",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "closure_type",
                                "custom_text": "",
                                "shopify_select": "recommendation",
                                "recommendation": "Drawstring",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            }
                        ],
                        "required_attribute": [
                            {
                                "amazon_attribute": "brand_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "part_number",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "model",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "product_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "style_name",
                                "custom_text": "",
                                "recommendation": "Diaper Bags",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "bullet_point1",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "department_name",
                                "custom_text": "",
                                "recommendation": "Boys",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "variant_title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_map",
                                "custom_text": "",
                                "recommendation": "Blue",
                                "shopify_attribute": "Size",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "material_type1",
                                "custom_text": "",
                                "recommendation": "Canvas",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_wheels",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer_contact_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_length",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_width",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_height",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name_unit_of_measure",
                                "custom_text": "",
                                "recommendation": "centiliters",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count_type",
                                "custom_text": "",
                                "recommendation": "Gram",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "country_of_origin",
                                "custom_text": "",
                                "recommendation": "United States",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_type",
                                "custom_text": "",
                                "recommendation": "Seller",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "external_product_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "maximum_retail_price",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "list_price_with_tax",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            }
                        ]
                    },
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "title": "fasffaffafsdf"
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518187",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518187",
        "title": "Professional DSLR Camera Kit",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518187",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518187",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            },
            {
                "_id": {
                    "$oid": "656477b6d77ad66a387341ba"
                },
                "container_id": "8414987518187",
                "shop_id": "122",
                "source_product_id": "8414987518187",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "attributes_mapping": {
                        "optional_attribute": [
                            {
                                "amazon_attribute": "update_delete",
                                "custom_text": "",
                                "shopify_select": "Search",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer",
                                "custom_text": "54654654",
                                "shopify_select": "custom",
                                "recommendation": "",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "closure_type",
                                "custom_text": "",
                                "shopify_select": "recommendation",
                                "recommendation": "Drawstring",
                                "shopify_attribute": "",
                                "meta_attribute_exist": false
                            }
                        ],
                        "required_attribute": [
                            {
                                "amazon_attribute": "brand_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "part_number",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "model",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "product_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "style_name",
                                "custom_text": "",
                                "recommendation": "Diaper Bags",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "bullet_point1",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "department_name",
                                "custom_text": "",
                                "recommendation": "Boys",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "variant_title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "color_map",
                                "custom_text": "",
                                "recommendation": "Blue",
                                "shopify_attribute": "Size",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "material_type1",
                                "custom_text": "",
                                "recommendation": "Canvas",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "number_of_wheels",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "manufacturer_contact_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_length",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_width",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "item_height",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "title",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "volume_capacity_name_unit_of_measure",
                                "custom_text": "",
                                "recommendation": "centiliters",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "unit_count_type",
                                "custom_text": "",
                                "recommendation": "Gram",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "country_of_origin",
                                "custom_text": "",
                                "recommendation": "United States",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_type",
                                "custom_text": "",
                                "recommendation": "Seller",
                                "shopify_attribute": "",
                                "shopify_select": "recommendation",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "warranty_description",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "brand",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "external_product_information",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "handle",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "maximum_retail_price",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            },
                            {
                                "amazon_attribute": "list_price_with_tax",
                                "custom_text": "",
                                "recommendation": "",
                                "shopify_attribute": "product_type",
                                "shopify_select": "Search",
                                "meta_attribute_exist": false
                            }
                        ]
                    },
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "title": "fasffaffafsdf"
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518189",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518189",
        "title": "Stainless Steel Coffee Maker",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518189",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518189",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518114",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518114",
        "title": "Ultra HD Smart TV",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518114",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518114",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518115",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518115",
        "title": "Portable Bluetooth Speaker",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518115",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518115",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518116",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518116",
        "title": "High-Performance Laptop",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518116",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518116",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518125",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518125",
        "title": "Elegant Leather Wallet",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518125",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518125",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    }
]


export const rowData2 = [
    {
        "_id": "15209",
        "container_id": "8414987518117",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518117",
        "title": "Solar-Powered Outdoor LED Lights",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518117",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518117",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518118",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518118",
        "title": "Multi-Function Food Processor",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518118",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518118",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518119",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518119",
        "title": "Stylish Women's Handbag",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518119",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518119",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518120",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518120",
        "title": "Smart Thermostat for Home Automation",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518120",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518120",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
    {
        "_id": "15209",
        "container_id": "8414987518121",
        "type": "variation",
        "user_id": "653a4e0c506bd44970069007",
        "visibility": "Catalog and Search",
        "description": "<p>this is a descrption</p>",
        "main_image": "https://cdn.shopify.com/s/files/1/0669/7582/6152/products/images.jpg?v=1698401981",
        "source_product_id": "8414987518121",
        "title": "Digital Drawing Tablet for Artists",
        "editedData": [
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734146"
                },
                "container_id": "8414987518121",
                "shop_id": "122",
                "source_product_id": "44996333240552",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120"
            },
            {
                "_id": {
                    "$oid": "656477b5d77ad66a38734193"
                },
                "container_id": "8414987518121",
                "shop_id": "122",
                "source_product_id": "44996333273320",
                "target_marketplace": "amazon",
                "user_id": "653a4e0c506bd44970069007",
                "category_settings": {
                    "barcode_exemption": false,
                    "browser_node_id": "9385658031",
                    "displayPath": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ],
                    "mutated": [],
                    "parentNodes": [
                        "1661666031",
                        "1661667031",
                        "9385640031",
                        "9385658031"
                    ],
                    "primary_category": "luggage",
                    "sub_category": "luggage",
                    "full_path": [
                        "Apps for Android",
                        "Magazines",
                        "Humour & Satire"
                    ]
                },
                "source_shop_id": "120",
                "edited_variant_attributes": [
                    {
                        "amazon_attribute": "catalog_number",
                        "custom_text": "",
                        "recommendation": "",
                        "shopify_attribute": "type",
                        "shopify_select": "Search",
                        "meta_attribute_exist": false
                    }
                ]
            }
        ]
    },
]
