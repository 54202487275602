import { Table } from "antd";
import React from "react";
import { BulkEditGridI } from "../../type";
import { useColumnRenderer } from "./columnRenderer";
import { useRowRenderer } from "./rowRenderer";

export const BulkEditGrid: React.FC<BulkEditGridI> = React.memo((props) => {
    const { rowSelection, state } = props;
    const { globalState } = state;
    const { columnRenderer } = useColumnRenderer({ ...props });
    const { rowsRenderer } = useRowRenderer({ ...props })

    return (
        <Table
            rowClassName={(record, index) => (index === 0 ? 'first__row' : '')}
            scroll={{ x: 'max-content' }}
            rowSelection={rowSelection}
            columns={columnRenderer(globalState?.columns?.filter((elem: { visible: any; }) => elem.visible) ?? [])}
            dataSource={[{ test: "test", key: 'test' }, ...(rowsRenderer(globalState?.rows) ?? [])]}
            pagination={false}
        />
    )
})