import React from "react";
import SideSheet from "../SideSheet/SideSheet";
import { _ACTIONS } from "../../context/actions";
import { BlockStack, Collapsible, InlineStack, Text, Icon, Checkbox } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { manageColHeading } from "../../helper";
import { ManageColumnsI } from "../../type";
import { trimTitle } from "../../helper/view/bulkeditView";

export const ManageColumns = React.memo((props: ManageColumnsI) => {
    const { isOpen, onClose, collapsible, selectedColumns, state } = props;
    const { isCollapsible, handleCollapsible } = collapsible;
    const { selectAllCols, selectAllManagedCols } = selectedColumns;
    const { globalState, dispatch } = state;

    function manageColumnRenderer() {
        let structure: React.ReactNode[] = [];
        if (globalState?.[_ACTIONS.MANAGE_COLS]) {
            Object.keys(globalState?.[_ACTIONS.MANAGE_COLS])?.forEach((elem: any) => {
                if (elem !== 'Variation') {
                    structure.push(renderManagedElements(elem));
                }
            });
        }
        return structure
    }

    const renderManagedElements = (elem: any) => {
        const header =
            elem === 'Mandantory' ?
                <Text as='h3' fontWeight='bold' variant='headingSm'>Required Attributes</Text> :
                manageColHeading(elem);

        const collapsible = (
            <Collapsible
                open={isCollapsible?.[elem]?.['isOpen']}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <div className='collapsible-inner'>
                    <BlockStack gap={"500"}>
                        <Checkbox
                            label="Select All"
                            checked={selectAllCols[elem]?.['selectAll']}
                            onChange={() => {
                                selectAllManagedCols(elem, selectAllCols[elem]?.['selectAll'], globalState?.[_ACTIONS.MANAGE_COLS][elem])
                            }}
                        />
                        <div className='managecolumns__parent'>
                            {
                                Object.keys(globalState?.[_ACTIONS.MANAGE_COLS]?.[elem])?.map((element: any, index: number) => {
                                    const key = globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['definition'] + 'renderManagedElements' + index;
                                    return <div key={key} className='managecolumns__child'>
                                        {globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label'] && <Checkbox
                                            label={trimTitle({
                                                title: globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label'],
                                                length: 30, substringLength: 30,
                                                tooltip: {
                                                    hasTooltip: true,
                                                    tooltipContent: globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label']
                                                }
                                            }) ?? "Select"}

                                            //globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label'], 30, 30, { hasTooltip: true, tooltipContent: globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label'] }
                                            checked={globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['visible']}
                                            onChange={() => {
                                                toggleColumn(elem, element, globalState?.[_ACTIONS.MANAGE_COLS]?.[elem][element]?.['label'])
                                            }}
                                        />}
                                    </div>
                                })
                            }
                        </div>
                    </BlockStack>
                </div>
            </Collapsible>
        );

        return (
            <BlockStack gap={"300"} key={elem}>
                {header}
                <div className="custom-dropdown--link side-sheet-contentWrapper">
                    <div
                        role='none'
                        className='collapsible--header'
                        onClick={() => { handleCollapsible(elem) }}>
                        <InlineStack align='space-between' blockAlign='center'>
                            <Text as='h3' fontWeight='bold' variant='headingSm' tone='subdued'>{elem}</Text>
                            <div>
                                {isCollapsible?.[elem]?.['isOpen'] ? (
                                    <Icon source={ChevronUpMinor} tone="base" />
                                ) : (
                                    <Icon source={ChevronDownMinor} tone="base" />
                                )}

                            </div>
                        </InlineStack>
                    </div>
                    {collapsible}
                </div>
            </BlockStack>
        );
    };

    function toggleColumn(key: string, sub_key: string, value: any) {

        let found: boolean = false;
        let tempCols: any = [...(globalState?.columns ?? [])];
        tempCols?.forEach((elem: any) => {
            if (!found && elem?.dataIndex === value) {
                elem.visible = !elem?.visible
            }
        })
        // Updating mangeCols keys
        let tempManageCols: any = { ...(globalState?.manage_columns ?? {}) };
        tempManageCols[key][sub_key]['visible'] = !tempManageCols[key][sub_key]['visible'];
        dispatch({
            type: _ACTIONS.COLUMNS,
            payload: {
                [_ACTIONS.COLUMNS]: tempCols,
                [_ACTIONS.MANAGE_COLS]: tempManageCols
            }
        })
    }

    return (
        <SideSheet isOpen={isOpen} onClose={() => {
            onClose(!isOpen);
        }} title="Manage Columns">
            {manageColumnRenderer()}
        </SideSheet>
    )
})