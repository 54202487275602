export const categories = [
    {
        "displayPath": [
            "Books",
            "Arts, Film & Photography",
            "Dance"
        ],
        "browser_node_id": "1318055031",
        "primary_category": "book",
        "sub_category": "bookloader"
    },
    {
        "displayPath": [
            "Toys & Games",
            "Arts & Crafts",
            "Clay & Dough"
        ],
        "browser_node_id": "1378135031",
        "primary_category": "kitchen",
        "sub_category": "artandcraftsupply"
    },
    {
        "displayPath": [
            "Books",
            "Humour"
        ],
        "browser_node_id": "1318143031",
        "primary_category": "book",
        "sub_category": "bookloader"
    },
    {
        "displayPath": [
            "Fashion",
            "Boys",
            "Shoes",
            "Casual Shoes",
            "Boots"
        ],
        "browser_node_id": "1983447031",
        "primary_category": "shoe_handbags",
        "sub_category": "boot"
    },
    {
        "displayPath": [
            "Books",
            "Arts, Film & Photography",
            "Cinema & Broadcast"
        ],
        "browser_node_id": "1318054031",
        "primary_category": "book",
        "sub_category": "bookloader"
    },
    {
        "displayPath": [
            "Sports, Fitness & Outdoors",
            "Airsoft",
            "Battery Chargers"
        ],
        "browser_node_id": "3403680031",
        "primary_category": "electronics_pc",
        "sub_category": "computer"
    },
    {
        "displayPath": [
            "Musical Instruments",
            "String Instruments",
            "Accessories",
            "Cases & Bags"
        ],
        "browser_node_id": "4654693031",
        "primary_category": "musical_instruments",
        "sub_category": "instrumentpartsandaccessories"
    },
    {
        "displayPath": [
            "Software",
            "Accounting & Finance",
            "Personal Finance",
            "Money Management & Budgeting"
        ],
        "browser_node_id": "5490211031",
        "primary_category": "video_games_games",
        "sub_category": "software"
    },
    {
        "displayPath": [
            "Industrial & Scientific",
            "Commercial Door Products",
            "Commercial Doors",
            "Fire Doors"
        ],
        "browser_node_id": "6306116031",
        "primary_category": "home_improvement",
        "sub_category": "door"
    }
]


//refered to as columns within the Bulk Edit Component
export const attributesTest = {
    "Mandantory": {
        "brand_name": {
            "definition": "Specify the product's brand.\n",
            "accepted_value": "Name of the brand under which products are sold. Usually mentioned on product or package. In case name is not mentioned, kindly use \"Generic\" as brand name",
            "example": "Samsonite\n",
            "label": "Brand Name (brand_name)"
        },
        "part_number": {
            "definition": "Specify the product's part number.\n",
            "accepted_value": "For most products, this will be identical to the model number or SKU; However, some manufacturers distinguish part number from model number.",
            "example": "AW80D-1AV\n",
            "label": "Manufacturer Part Number (part_number)"
        },
        "model": {
            "definition": "Specify the product's model number. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "A text string up to 50 characters.\n",
            "example": "AW80D-1AV\n",
            "label": "Model Number (model)"
        },
        "product_description": {
            "definition": "Text - maximum 2000 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc",
            "accepted_value": "Text - maximum 2000 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc",
            "example": "The Samsonite Hyperspace Expandable Carry-On features a protective honeycomb frame and dual spinner wheels. Its security friendly features and fully featured interiors are ready for any journey.\n",
            "label": "Product Description (product_description)"
        },
        "style_name": {
            "definition": "Select the value that best describes the product's style.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Luggage\n",
            "label": "Style (style_name)",
            "amazon_recommendation": {
                "Backpacks": "Backpacks",
                "Briefcases": "Briefcases",
                "Diaper Bags": "Diaper Bags",
                "Fashion Waist Packs": "Fashion Waist Packs",
                "Gym Bags": "Gym Bags",
                "Laptop Bags & Cases": "Laptop Bags & Cases",
                "Luggage": "Luggage",
                "Messenger Bags": "Messenger Bags",
                "Travel Accessories": "Travel Accessories",
                "Umbrellas": "Umbrellas",
                "Rucksacks": "Rucksacks"
            }
        },
        "bullet_point1": {
            "definition": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "accepted_value": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "example": "Expandable\n",
            "label": "Bullet Point (bullet_point1)"
        },
        "department_name": {
            "definition": "Select the value that best describes the gender associated with the product.\n",
            "accepted_value": "Department refers to the gender which the product would be most suitable for(e.g: Male, Female, Unisex)",
            "example": "Children\n",
            "label": "Gender (department_name)",
            "amazon_recommendation": {
                "Men": "Men",
                "Women": "Women",
                "Children": "Children",
                "Boys": "Boys",
                "Girls": "Girls",
                "Unisex": "Unisex"
            }
        },
        "color_name": {
            "definition": "Specify the product's primary colour. You must provide a value for Colour if the product is a \"Child\" product of a \"Parent\" product with a Variation Theme of or including COLOR_NAME.\n",
            "accepted_value": "Mention the primary or prominent color of the product. Mention \"multicolor\" if there are more than one color",
            "example": "Navy Blue\n",
            "label": "Color (color_name)"
        },
        "color_map": {
            "definition": "Select the value that best describes the product's primary colour if the product's primary colour is non-standard, e.g. navy blue. This information will support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Blue",
            "label": "Colour Map (color_map)",
            "amazon_recommendation": {
                "Beige": "Beige",
                "Black": "Black",
                "Blue": "Blue",
                "Brown": "Brown",
                "Gold": "Gold",
                "Green": "Green",
                "Grey": "Grey",
                "Multi-Colour": "Multi-Colour",
                "Off-White": "Off-White",
                "Orange": "Orange",
                "Pink": "Pink",
                "Purple": "Purple",
                "Red": "Red",
                "Silver": "Silver",
                "Transparent": "Transparent",
                "Turquoise": "Turquoise",
                "White": "White",
                "Yellow": "Yellow"
            }
        },
        "material_type1": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "label": "Material Type (material_type1)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "number_of_wheels": {
            "definition": "Specify the product's total number of wheels. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "A positive integer.\n",
            "example": "2\n",
            "label": "Number of Wheels (number_of_wheels)"
        },
        "manufacturer_contact_information": {
            "definition": "Address and Customer Care Details of the manufacturer, or where the manufacturer is not the packer, the address and customer care details of the manufacturer and packer",
            "accepted_value": "Provide the address and contact number of the manufacturer. You can also provide e-mail, customer care number & website URL of the manufacturer If available.",
            "example": "ABC Manufacturer, Industrial Estate, Mumbai, India. Customer Care 000 999 999999",
            "label": "Manufacturer Contact (manufacturer_contact_information)"
        },
        "item_length": {
            "definition": "Specify the length of the product in centimeters and inches. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.",
            "accepted_value": "Provide the item length of the product.",
            "example": 12,
            "label": "Item Length (item_length)"
        },
        "item_width": {
            "definition": "Specify the width of the product in centimeters and inches. This information will surface in the Product Specifications widget on the detail page.",
            "accepted_value": "Provide the item width of the product.",
            "example": 12,
            "label": "Item Width (item_width)"
        },
        "item_height": {
            "definition": "Specify the height of the product in centimeters and inches. This information will surface in the Product Specifications widget on the detail page.",
            "accepted_value": "Provide the item height of the product.",
            "example": 12,
            "label": "Item Height (item_height)"
        },
        "volume_capacity_name": {
            "definition": "Specify the volume capacity of the product in liters. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": "40\n",
            "label": "Volume Capacity (volume_capacity_name)"
        },
        "volume_capacity_name_unit_of_measure": {
            "definition": "Select a unit of measure for Volume Capacity. You must provide a Volume Capacity Unit of Measure if you provided a value for Volume Capacity. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Liters\n",
            "label": "Volume Capacity Unit of Measure (volume_capacity_name_unit_of_measure)",
            "amazon_recommendation": {
                "cup": "cup",
                "microliters": "microliters",
                "cubic_feet": "cubic_feet",
                "centiliters": "centiliters",
                "Gallons": "Gallons",
                "Quarts": "Quarts",
                "nanoliters": "nanoliters",
                "Liters": "Liters",
                "picoliters": "picoliters",
                "Unknown modifier": "Unknown modifier",
                "Fluid Ounces": "Fluid Ounces",
                "cubic_meters": "cubic_meters",
                "Pints": "Pints",
                "cubic_yards": "cubic_yards",
                "cubic-in": "cubic-in",
                "imperial_gallons": "imperial_gallons",
                "Milliliters": "Milliliters",
                "cubic_centimeters": "cubic_centimeters",
                "deciliters": "deciliters"
            }
        },
        "unit_count": {
            "definition": "Indicate the number count of a specific product. Skeins of yarn, tubes of paint, and so on. Rarely used in the Toy or Baby category.",
            "accepted_value": "provide the number of units in the product.",
            "example": 12,
            "label": "Unit Count (unit_count)"
        },
        "unit_count_type": {
            "definition": "Select the unit of measure for PPU Count. If PPU Count is used, you must also enter the PPU Count Unit Of Measure.",
            "accepted_value": "Specify the unit of measure for Unit Count. If Unit Count is used, you must also enter the Unit Count Unit Of Measure.",
            "example": "Set, Unit, Count",
            "label": "Unit Count Type (unit_count_type)",
            "amazon_recommendation": {
                "Count": "Count",
                "Fl Oz": "Fl Oz",
                "Ounce": "Ounce",
                "Pound": "Pound",
                "Gram": "Gram",
                "Foot": "Foot",
                "Sq Ft": "Sq Ft"
            }
        },
        "country_of_origin": {
            "definition": "The country where the product was issued.",
            "accepted_value": "Specify the country where the product was issued.",
            "example": "Japan",
            "label": "Country Of Origin (country_of_origin)",
            "amazon_recommendation": {
                "India": "India",
                "China": "China",
                "United States": "United States",
                "Afghanistan": "Afghanistan",
                "Aland Islands": "Aland Islands",
                "Albania": "Albania",
                "Algeria": "Algeria",
                "American Samoa": "American Samoa",
                "Andorra": "Andorra",
                "Angola": "Angola",
                "Anguilla": "Anguilla",
                "Antarctica": "Antarctica",
                "Antigua And Barbuda": "Antigua And Barbuda",
                "Argentina": "Argentina",
                "Armenia": "Armenia",
                "Aruba": "Aruba",
                "Australia": "Australia",
                "Austria": "Austria",
                "Azerbaijan": "Azerbaijan",
                "Bahamas": "Bahamas",
                "Bahrain": "Bahrain",
                "Bangladesh": "Bangladesh",
                "Barbados": "Barbados",
                "Belarus": "Belarus",
                "Belgium": "Belgium",
                "Belize": "Belize",
                "Benin": "Benin",
                "Bermuda": "Bermuda",
                "Bhutan": "Bhutan",
                "Bolivia": "Bolivia",
                "Bosnia and Herzegovina": "Bosnia and Herzegovina",
                "Botswana": "Botswana",
                "Bouvet Island": "Bouvet Island",
                "Brazil": "Brazil",
                "British Indian Ocean Territory": "British Indian Ocean Territory",
                "British Virgin Islands": "British Virgin Islands",
                "Brunei": "Brunei",
                "Bulgaria": "Bulgaria",
                "Burkina Faso": "Burkina Faso",
                "Burma (Myanmar)": "Burma (Myanmar)",
                "Burundi": "Burundi",
                "Cambodia": "Cambodia",
                "Cameroon": "Cameroon",
                "Canada": "Canada",
                "Cape Verde": "Cape Verde",
                "Cayman Islands": "Cayman Islands",
                "Central African Republic": "Central African Republic",
                "Chad": "Chad",
                "Chile": "Chile",
                "Christmas Island": "Christmas Island",
                "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
                "Colombia": "Colombia",
                "Comoros": "Comoros",
                "Cook Islands": "Cook Islands",
                "Costa Rica": "Costa Rica",
                "Croatia": "Croatia",
                "Cuba": "Cuba",
                "Cyprus": "Cyprus",
                "Czech Republic": "Czech Republic",
                "Democratic Republic of the Congo": "Democratic Republic of the Congo",
                "Denmark": "Denmark",
                "Djibouti": "Djibouti",
                "Dominica": "Dominica",
                "Dominican Republic": "Dominican Republic",
                "Ecuador": "Ecuador",
                "Egypt": "Egypt",
                "El Salvador": "El Salvador",
                "Equatorial Guinea": "Equatorial Guinea",
                "Eritrea": "Eritrea",
                "Estonia": "Estonia",
                "Ethiopia": "Ethiopia",
                "Falkland Islands": "Falkland Islands",
                "Faroe Islands": "Faroe Islands",
                "Fiji": "Fiji",
                "Finland": "Finland",
                "France": "France",
                "French Guiana": "French Guiana",
                "French Polynesia": "French Polynesia",
                "French Southern Territories": "French Southern Territories",
                "Great Britain": "Great Britain",
                "Gabon": "Gabon",
                "Gambia": "Gambia",
                "Georgia": "Georgia",
                "Germany": "Germany",
                "Ghana": "Ghana",
                "Gibraltar": "Gibraltar",
                "Greece": "Greece",
                "Greenland": "Greenland",
                "Grenada": "Grenada",
                "Guadeloupe": "Guadeloupe",
                "Guam": "Guam",
                "Guatemala": "Guatemala",
                "Guernsey": "Guernsey",
                "Guinea": "Guinea",
                "Guinea-Bissau": "Guinea-Bissau",
                "Guyana": "Guyana",
                "Haiti": "Haiti",
                "Heard and McDonald Islands": "Heard and McDonald Islands",
                "Holy See (Vatican City)": "Holy See (Vatican City)",
                "Honduras": "Honduras",
                "Hong Kong": "Hong Kong",
                "Hungary": "Hungary",
                "Iceland": "Iceland",
                "Indonesia": "Indonesia",
                "Iran": "Iran",
                "Iraq": "Iraq",
                "Ireland": "Ireland",
                "Isle of Man": "Isle of Man",
                "Israel": "Israel",
                "Italy": "Italy",
                "Ivory Coast": "Ivory Coast",
                "Jamaica": "Jamaica",
                "Japan": "Japan",
                "Jersey": "Jersey",
                "Jordan": "Jordan",
                "Kazakhstan": "Kazakhstan",
                "Kenya": "Kenya",
                "Kiribati": "Kiribati",
                "Kuwait": "Kuwait",
                "Kyrgyzstan": "Kyrgyzstan",
                "Laos": "Laos",
                "Latvia": "Latvia",
                "Lebanon": "Lebanon",
                "Lesotho": "Lesotho",
                "Liberia": "Liberia",
                "Libya": "Libya",
                "Liechtenstein": "Liechtenstein",
                "Lithuania": "Lithuania",
                "Luxembourg": "Luxembourg",
                "Macau": "Macau",
                "Macedonia": "Macedonia",
                "Madagascar": "Madagascar",
                "Malawi": "Malawi",
                "Malaysia": "Malaysia",
                "Maldives": "Maldives",
                "Mali": "Mali",
                "Malta": "Malta",
                "Marshall Islands": "Marshall Islands",
                "Martinique": "Martinique",
                "Mauritania": "Mauritania",
                "Mauritius": "Mauritius",
                "Mayotte": "Mayotte",
                "Mexico": "Mexico",
                "Micronesia": "Micronesia",
                "Moldova": "Moldova",
                "Monaco": "Monaco",
                "Mongolia": "Mongolia",
                "Montserrat": "Montserrat",
                "Morocco": "Morocco",
                "Mozambique": "Mozambique",
                "Namibia": "Namibia",
                "Nauru": "Nauru",
                "Nepal": "Nepal",
                "Netherlands": "Netherlands",
                "New Caledonia": "New Caledonia",
                "New Zealand": "New Zealand",
                "Nicaragua": "Nicaragua",
                "Niger": "Niger",
                "Nigeria": "Nigeria",
                "Niue": "Niue",
                "Norfolk Island": "Norfolk Island",
                "North Korea": "North Korea",
                "Northern Mariana Islands": "Northern Mariana Islands",
                "Norway": "Norway",
                "Oman": "Oman",
                "Pakistan": "Pakistan",
                "Palau": "Palau",
                "Palestinian Territory": "Palestinian Territory",
                "Panama": "Panama",
                "Papua New Guinea": "Papua New Guinea",
                "Paraguay": "Paraguay",
                "Peru": "Peru",
                "Philippines": "Philippines",
                "Pitcairn Islands": "Pitcairn Islands",
                "Poland": "Poland",
                "Portugal": "Portugal",
                "Puerto Rico": "Puerto Rico",
                "Qatar": "Qatar",
                "Republic of the Congo": "Republic of the Congo",
                "Reunion": "Reunion",
                "Romania": "Romania",
                "Russia": "Russia",
                "Rwanda": "Rwanda",
                "S. Georgia and S. Sandwich Isls.": "S. Georgia and S. Sandwich Isls.",
                "Saint Helena": "Saint Helena",
                "Saint Kitts And Nevis": "Saint Kitts And Nevis",
                "Saint Lucia": "Saint Lucia",
                "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
                "Saint Vincent And The Grenadines": "Saint Vincent And The Grenadines",
                "Saint-Martin": "Saint-Martin",
                "Samoa": "Samoa",
                "San Marino": "San Marino",
                "Sao Tome And Principe": "Sao Tome And Principe",
                "Saudi Arabia": "Saudi Arabia",
                "Senegal": "Senegal",
                "Serbia": "Serbia",
                "Seychelles": "Seychelles",
                "Sierra Leone": "Sierra Leone",
                "Singapore": "Singapore",
                "Slovakia": "Slovakia",
                "Slovenia": "Slovenia",
                "Solomon Islands": "Solomon Islands",
                "Somalia": "Somalia",
                "South Africa": "South Africa",
                "South Korea": "South Korea",
                "Spain": "Spain",
                "Sri Lanka": "Sri Lanka",
                "Sudan": "Sudan",
                "Suriname": "Suriname",
                "Svalbard": "Svalbard",
                "Swaziland": "Swaziland",
                "Sweden": "Sweden",
                "Switzerland": "Switzerland",
                "Syria": "Syria",
                "Taiwan": "Taiwan",
                "Tajikistan": "Tajikistan",
                "Tanzania": "Tanzania",
                "Thailand": "Thailand",
                "Timor-Leste": "Timor-Leste",
                "Togo": "Togo",
                "Tokelau": "Tokelau",
                "Tonga": "Tonga",
                "Trinidad And Tobago": "Trinidad And Tobago",
                "Tunisia": "Tunisia",
                "Turkey": "Turkey",
                "Turkmenistan": "Turkmenistan",
                "Turks And Caicos Islands": "Turks And Caicos Islands",
                "Tuvalu": "Tuvalu",
                "Uganda": "Uganda",
                "Ukraine": "Ukraine",
                "United Arab Emirates": "United Arab Emirates",
                "Uruguay": "Uruguay",
                "US Minor Outlying Islands": "US Minor Outlying Islands",
                "US Virgin Islands": "US Virgin Islands",
                "Uzbekistan": "Uzbekistan",
                "Vanuatu": "Vanuatu",
                "Venezuela": "Venezuela",
                "Vietnam": "Vietnam",
                "Wallis and Futuna": "Wallis and Futuna",
                "Western Sahara": "Western Sahara",
                "Yemen": "Yemen",
                "Zambia": "Zambia",
                "Zimbabwe": "Zimbabwe"
            }
        },
        "warranty_type": {
            "definition": "Select the value that best describes the product's warranty type.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Manufacturer",
            "label": "Warranty Type (warranty_type)",
            "amazon_recommendation": {
                "Manufacturer": "Manufacturer",
                "Seller": "Seller",
                "Manufacturer & Seller": "Manufacturer & Seller",
                "No Warranty": "No Warranty"
            }
        },
        "warranty_description": {
            "definition": "Provide a description of the product's warranty. This information will surface in the Product Specifications widget on the detail page.",
            "accepted_value": "Please describe the warranty given with the product. The terms of warranty should be described as specified by the manufacturer.",
            "example": "1 year manufacturer warranty is non-transferable and valid for 1 year from the original date of purchase.",
            "label": "Warranty Description (warranty_description)"
        },
        "external_product_information": {
            "definition": "Provide the applicable 6/8 digit HSN tax code for your product.",
            "accepted_value": "Provide the applicable 6/8 digit HSN tax code for your product.",
            "example": "610510, 61051048",
            "label": "HSN Code (external_product_information)"
        },
        "maximum_retail_price": {
            "definition": "This is the maximum retail price that is physically printed on pre-packaged products by manufacturer according to legal metrology act. This is the maximum price that seller can charge a customer.  This attribute is only applicable to IN marketplace.",
            "accepted_value": "Maximum Retail Price (MRP) indicates the price mentioned on the product or package. This is the highest price at which the product can be sold to the customer. Please do not use commas or currency signs",
            "example": 195,
            "label": "Maximum Retail Price (maximum_retail_price)"
        },
        "list_price_with_tax": {
            "definition": "The manufacturer's suggested retail price.",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted. Currency symbols are not accepted.",
            "example": "299.99",
            "label": "List Price (list_price_with_tax)"
        }
    },
    "Variation": {
        "variation_theme": {
            "definition": "Select the value that best describes how the \"Parent\" product varies. This value must be the same for both the \"Parent\" product and its \"Child\" products. LEAVE THIS FIELD BLANK FOR PRODUCTS WITHOUT VARIATIONS.\n",
            "accepted_value": "Select from the list of valid values. LEAVE THIS FIELD BLANK FOR CHILD PRODUCTS OR PRODUCTS WITHOUT VARIATIONS.\n",
            "example": "COLOR_NAME\n",
            "label": "Variation Theme (variation_theme)",
            "amazon_recommendation": {
                "color-size": "color-size",
                "Size": "Size",
                "Color": "Color",
                "ColorName": "ColorName"
            },
            "required_variant_attributes": {
                "color-size": [
                    "color_name",
                    "color_map",
                    "size_name",
                    "size_map"
                ],
                "Size": [
                    "size_name",
                    "size_map"
                ],
                "Color": [
                    "color_name",
                    "color_map"
                ],
                "ColorName": [
                    "color_name"
                ]
            }
        }
    },
    "Basic": {
        "update_delete": {
            "definition": "\"Specifies the type of operation (Update, PartialUpdate or Delete) to be done on the data provided. If left blank, the default behaviour is \"\"Update.\"\"  Use \"\"Update\"\" whenever you are changing any field in the existing product's information, including reducing the inventory to 0.  Only use \"\"Delete\"\" when you wish to remove a product completely and permanently from the catalogue. \n\nAfter you have established a SKU for a product, please do not try changing it without first deleting the associated product from our systems through a delete feed.\"\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Update",
            "label": "Update Delete (update_delete)",
            "amazon_recommendation": {
                "Update": "Update",
                "PartialUpdate": "PartialUpdate",
                "Delete": "Delete"
            }
        },
        "manufacturer": {
            "definition": "Specify the manufacturer for your product",
            "accepted_value": "Specify the manufacturer of the product as mentioned on the product or package",
            "example": "XYZ Pvt Ltd",
            "label": "Manufacturer (manufacturer)"
        },
        "model_year": {
            "definition": "Specify the year the product's model was introduced to the market.\n",
            "accepted_value": "A number with 4 digits.\n",
            "example": 2010,
            "label": "Model Year"
        },
        "closure_type": {
            "definition": "Select the value that best describes the product's closure type. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Buckle\n",
            "label": "Closure Type (closure_type)",
            "amazon_recommendation": {
                "Buckle": "Buckle",
                "Buttons": "Buttons",
                "Clasp": "Clasp",
                "Drawstring": "Drawstring",
                "Flap": "Flap",
                "Hook & Loop": "Hook & Loop",
                "Latch": "Latch",
                "Magnetic": "Magnetic",
                "Push Button": "Push Button",
                "Quick Lace": "Quick Lace",
                "Snap": "Snap",
                "Turnlock": "Turnlock",
                "Zipper": "Zipper"
            }
        },
        "outer_material_type1": {
            "definition": "",
            "accepted_value": "Specify the best option for the material on the outside of the item",
            "example": "Hardside, Softside",
            "label": "Outer Material"
        },
        "outer_material_type2": {
            "definition": "",
            "accepted_value": "Specify the best option for the material on the outside of the item",
            "example": "Hardside, Softside",
            "label": "Outer Material"
        },
        "outer_material_type3": {
            "definition": "",
            "accepted_value": "Specify the best option for the material on the outside of the item",
            "example": "Hardside, Softside",
            "label": "Outer Material"
        },
        "outer_material_type4": {
            "definition": "",
            "accepted_value": "Specify the best option for the material on the outside of the item",
            "example": "Hardside, Softside",
            "label": "Outer Material"
        },
        "outer_material_type5": {
            "definition": "",
            "accepted_value": "Specify the best option for the material on the outside of the item",
            "example": "Hardside, Softside",
            "label": "Outer Material"
        },
        "inner_material_type1": {
            "definition": "Select the value(s) that best describe(s) the product's inner material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Specify the inner material of the product. Can be same as main material used.",
            "example": "Canvas\n",
            "label": "Inner Material Type(s) (inner_material_type1)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Lycra": "Lycra",
                "Mesh": "Mesh",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Plastic": "Plastic",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Rubber": "Rubber",
                "Satin": "Satin",
                "Silk": "Silk",
                "Suede": "Suede",
                "Synthetic": "Synthetic",
                "Velvet": "Velvet",
                "Wool": "Wool"
            }
        },
        "inner_material_type2": {
            "definition": "Select the value(s) that best describe(s) the product's inner material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Specify the inner material of the product. Can be same as main material used.",
            "example": "Canvas\n",
            "label": "Inner Material Type(s) (inner_material_type2)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Lycra": "Lycra",
                "Mesh": "Mesh",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Plastic": "Plastic",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Rubber": "Rubber",
                "Satin": "Satin",
                "Silk": "Silk",
                "Suede": "Suede",
                "Synthetic": "Synthetic",
                "Velvet": "Velvet",
                "Wool": "Wool"
            }
        },
        "inner_material_type3": {
            "definition": "Select the value(s) that best describe(s) the product's inner material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Specify the inner material of the product. Can be same as main material used.",
            "example": "Canvas\n",
            "label": "Inner Material Type(s) (inner_material_type3)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Lycra": "Lycra",
                "Mesh": "Mesh",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Plastic": "Plastic",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Rubber": "Rubber",
                "Satin": "Satin",
                "Silk": "Silk",
                "Suede": "Suede",
                "Synthetic": "Synthetic",
                "Velvet": "Velvet",
                "Wool": "Wool"
            }
        },
        "inner_material_type4": {
            "definition": "Select the value(s) that best describe(s) the product's inner material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Specify the inner material of the product. Can be same as main material used.",
            "example": "Canvas\n",
            "label": "Inner Material Type(s) (inner_material_type4)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Lycra": "Lycra",
                "Mesh": "Mesh",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Plastic": "Plastic",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Rubber": "Rubber",
                "Satin": "Satin",
                "Silk": "Silk",
                "Suede": "Suede",
                "Synthetic": "Synthetic",
                "Velvet": "Velvet",
                "Wool": "Wool"
            }
        }
    },
    "Discovery": {
        "catalog_number": {
            "definition": "Specify the product's catalog number.\n",
            "accepted_value": "A text string up to 50 characters.\n",
            "example": "123456\n",
            "label": "Catalog Number (catalog_number)"
        },
        "generic_keywords": {
            "definition": "Search terms that describe your product: no repetition, no competitor brand names or ASINs.",
            "accepted_value": "Search terms that describe your product: no repetition, no competitor brand names or ASINs.",
            "example": "Electric",
            "label": "Search Terms (generic_keywords)"
        },
        "platinum_keywords": {
            "definition": "Provide platinum keywords to support product discoverability within your storefront. This value must be the same for both the \"Parent\" product and its \"Child\" products.\n",
            "accepted_value": "A text string up to 50 characters.\n",
            "example": "mycompanybrowsenode001\n",
            "label": "Platinum Keyword(s) (platinum_keywords)"
        },
        "collection_name": {
            "definition": "Specify the product's collection.\n",
            "accepted_value": "A text string up to 50 characters.\n",
            "example": "Salsa Air\n",
            "label": "Collection (collection_name)"
        },
        "size_name": {
            "definition": "Specify the relative size of the product. If your product has variations in size, you must provide size for child SKUs, but not for parent SKUs.",
            "accepted_value": "Mention the numeric or text version of the item's size. (e.g: Small, Medium, Large, 40, 42, 44, etc.)",
            "example": "S",
            "label": "Size (size_name)"
        },
        "lock_type": {
            "definition": "Select the value that best describes the product's lock type. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Butterfly Lock\n",
            "label": "Lock Type"
        },
        "number_of_compartments": {
            "definition": "Specify the product's total number of compartments.",
            "accepted_value": "A positive integer.",
            "example": 3,
            "label": "Number of Compartments"
        },
        "occasion_type1": {
            "definition": "Select the value(s) that best describe(s) the occasion(s) associated with the product.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Backpacking\n",
            "label": "Occasion(s) (occasion_type1)",
            "amazon_recommendation": {
                "Backpacking": "Backpacking",
                "Biking": "Biking",
                "Camping": "Camping",
                "Cycling": "Cycling",
                "Daytrip": "Daytrip",
                "Expedition": "Expedition",
                "Gym": "Gym",
                "Hiking": "Hiking",
                "Photography": "Photography",
                "Running": "Running"
            }
        },
        "occasion_type2": {
            "definition": "Select the value(s) that best describe(s) the occasion(s) associated with the product.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Backpacking\n",
            "label": "Occasion(s) (occasion_type2)",
            "amazon_recommendation": {
                "Backpacking": "Backpacking",
                "Biking": "Biking",
                "Camping": "Camping",
                "Cycling": "Cycling",
                "Daytrip": "Daytrip",
                "Expedition": "Expedition",
                "Gym": "Gym",
                "Hiking": "Hiking",
                "Photography": "Photography",
                "Running": "Running"
            }
        },
        "occasion_type3": {
            "definition": "Select the value(s) that best describe(s) the occasion(s) associated with the product.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Backpacking\n",
            "label": "Occasion(s) (occasion_type3)",
            "amazon_recommendation": {
                "Backpacking": "Backpacking",
                "Biking": "Biking",
                "Camping": "Camping",
                "Cycling": "Cycling",
                "Daytrip": "Daytrip",
                "Expedition": "Expedition",
                "Gym": "Gym",
                "Hiking": "Hiking",
                "Photography": "Photography",
                "Running": "Running"
            }
        },
        "occasion_type4": {
            "definition": "Select the value(s) that best describe(s) the occasion(s) associated with the product.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Backpacking\n",
            "label": "Occasion(s) (occasion_type4)",
            "amazon_recommendation": {
                "Backpacking": "Backpacking",
                "Biking": "Biking",
                "Camping": "Camping",
                "Cycling": "Cycling",
                "Daytrip": "Daytrip",
                "Expedition": "Expedition",
                "Gym": "Gym",
                "Hiking": "Hiking",
                "Photography": "Photography",
                "Running": "Running"
            }
        },
        "subject_character": {
            "definition": "Select the value(s) that best describe(s) the character(s) depicted on the product. This information will support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Hulk\n",
            "label": "Character(s) (subject_character)",
            "amazon_recommendation": {
                "101 Dalmations": "101 Dalmations",
                "Action Man": "Action Man",
                "Arielle": "Arielle",
                "Asterix": "Asterix",
                "Avatar": "Avatar",
                "Babar": "Babar",
                "Bambi": "Bambi",
                "Barbapapa": "Barbapapa",
                "Barbie": "Barbie",
                "Barney & Friends": "Barney & Friends",
                "Batman": "Batman",
                "Ben 10": "Ben 10",
                "Ben 10 Alien Force": "Ben 10 Alien Force",
                "Benjamin": "Benjamin",
                "Bob the Builder": "Bob the Builder",
                "Bolt": "Bolt",
                "Bugs Bunny": "Bugs Bunny",
                "Captain America": "Captain America",
                "Captain Scarlet": "Captain Scarlet",
                "Care Bears": "Care Bears",
                "Cars": "Cars",
                "Charlie & Lola": "Charlie & Lola",
                "Cinderella": "Cinderella",
                "Conni": "Conni",
                "dirtgirlworld": "dirtgirlworld",
                "Disney": "Disney",
                "Disney Princess": "Disney Princess",
                "Donald Duck": "Donald Duck",
                "Dora the Explorer": "Dora the Explorer",
                "Dragonball Z": "Dragonball Z",
                "Felix": "Felix",
                "Finding Nemo": "Finding Nemo",
                "Flash & Dash": "Flash & Dash",
                "Franklin": "Franklin",
                "Garfield": "Garfield",
                "George of the Jungle": "George of the Jungle",
                "GI Joe": "GI Joe",
                "Hannah Montana": "Hannah Montana",
                "Harry Potter": "Harry Potter",
                "Hello Kitty": "Hello Kitty",
                "He-Man": "He-Man",
                "Heroes": "Heroes",
                "Hulk": "Hulk",
                "Indiana Jones": "Indiana Jones",
                "Iron Man": "Iron Man",
                "Jeep": "Jeep",
                "Jelly Belly": "Jelly Belly",
                "Kung Fu Panda": "Kung Fu Panda",
                "Lion King": "Lion King",
                "Little Bugs": "Little Bugs",
                "Looney Tunes": "Looney Tunes",
                "Marvel Heroes": "Marvel Heroes",
                "Merlin": "Merlin",
                "Mickey Mouse": "Mickey Mouse",
                "Monsters vs Aliens": "Monsters vs Aliens",
                "Monsters, Inc.": "Monsters, Inc.",
                "Noddy": "Noddy",
                "Peter Pan": "Peter Pan",
                "Petshop": "Petshop",
                "Pingu": "Pingu",
                "Pink Panther": "Pink Panther",
                "Pirates of the Carribean": "Pirates of the Carribean",
                "Playboy": "Playboy",
                "Polly Pocket": "Polly Pocket",
                "Popeye": "Popeye",
                "Power Rangers": "Power Rangers",
                "Prince Caspian": "Prince Caspian",
                "Prince of Persia": "Prince of Persia",
                "Puppy Love": "Puppy Love",
                "Rapunzel": "Rapunzel",
                "Ratatouille": "Ratatouille",
                "Roary the Racing Car": "Roary the Racing Car",
                "Sarah Jane Adventures": "Sarah Jane Adventures",
                "Scooby-Doo": "Scooby-Doo",
                "Shaktimaan": "Shaktimaan",
                "Shrek": "Shrek",
                "Simpsons": "Simpsons",
                "Sleeping Beauty": "Sleeping Beauty",
                "Smurfs": "Smurfs",
                "Snoopy": "Snoopy",
                "Sooty & Sweep": "Sooty & Sweep",
                "Spider-Man": "Spider-Man",
                "SpongeBob": "SpongeBob",
                "Spot the Dog": "Spot the Dog",
                "Star Trek": "Star Trek",
                "Star Wars": "Star Wars",
                "Superman": "Superman",
                "Teenage Mutant Ninja Turtles": "Teenage Mutant Ninja Turtles",
                "The Adventures of Tintin": "The Adventures of Tintin",
                "The Green Hornet": "The Green Hornet",
                "The Jungle Book": "The Jungle Book",
                "The Little King": "The Little King",
                "The Little Polar Bear": "The Little Polar Bear",
                "The Lord of the Rings": "The Lord of the Rings",
                "Thomas & Friends": "Thomas & Friends",
                "Three Investigators": "Three Investigators",
                "Thunderbirds": "Thunderbirds",
                "Tom & Jerry": "Tom & Jerry",
                "Toy Story": "Toy Story",
                "Tractor Tom": "Tractor Tom",
                "Transformers": "Transformers",
                "Tweenies": "Tweenies",
                "Victor & Hugo": "Victor & Hugo",
                "WALL-E": "WALL-E",
                "Watchmen": "Watchmen",
                "Winnie-the-Pooh": "Winnie-the-Pooh",
                "Wolverine": "Wolverine",
                "Wonder Pets!": "Wonder Pets!",
                "WWE": "WWE",
                "X-Men": "X-Men",
                "Zorro": "Zorro",
                "How to Train Your Dragon": "How to Train Your Dragon"
            }
        },
        "special_features1": {
            "definition": "Select the value(s) that best describe(s) the product's special feature(s) in order of importance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Expandable\n",
            "label": "Special Feature(s) (special_features1)",
            "amazon_recommendation": {
                "Back Padding": "Back Padding",
                "Chest Strap": "Chest Strap",
                "Earphone Cord Support": "Earphone Cord Support",
                "Expandable": "Expandable",
                "Fits DIN A4": "Fits DIN A4",
                "Fits Letter": "Fits Letter",
                "Helmet Compartment": "Helmet Compartment",
                "Hip Belt": "Hip Belt",
                "Hydration": "Hydration",
                "Integrated Rain Cover": "Integrated Rain Cover",
                "Laptop Compartment": "Laptop Compartment",
                "Snowboard Strap": "Snowboard Strap",
                "Telescope Handle": "Telescope Handle",
                "Water Bottle / Umbrella Pouch": "Water Bottle / Umbrella Pouch",
                "Wheels": "Wheels",
                "360 Degree Rotating Wheel": "360 Degree Rotating Wheel",
                "Inner Mesh Pocket": "Inner Mesh Pocket",
                "Laundry Compartment": "Laundry Compartment",
                "Packing Straps": "Packing Straps",
                "Shirt Compartment": "Shirt Compartment",
                "Shoe Compartment": "Shoe Compartment",
                "Zip Closure": "Zip Closure",
                "Back Strap": "Back Strap",
                "Padded Handles": "Padded Handles",
                "Shoulder Strap": "Shoulder Strap"
            }
        },
        "special_features2": {
            "definition": "Select the value(s) that best describe(s) the product's special feature(s) in order of importance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Expandable\n",
            "label": "Special Feature(s) (special_features2)",
            "amazon_recommendation": {
                "Back Padding": "Back Padding",
                "Chest Strap": "Chest Strap",
                "Earphone Cord Support": "Earphone Cord Support",
                "Expandable": "Expandable",
                "Fits DIN A4": "Fits DIN A4",
                "Fits Letter": "Fits Letter",
                "Helmet Compartment": "Helmet Compartment",
                "Hip Belt": "Hip Belt",
                "Hydration": "Hydration",
                "Integrated Rain Cover": "Integrated Rain Cover",
                "Laptop Compartment": "Laptop Compartment",
                "Snowboard Strap": "Snowboard Strap",
                "Telescope Handle": "Telescope Handle",
                "Water Bottle / Umbrella Pouch": "Water Bottle / Umbrella Pouch",
                "Wheels": "Wheels",
                "360 Degree Rotating Wheel": "360 Degree Rotating Wheel",
                "Inner Mesh Pocket": "Inner Mesh Pocket",
                "Laundry Compartment": "Laundry Compartment",
                "Packing Straps": "Packing Straps",
                "Shirt Compartment": "Shirt Compartment",
                "Shoe Compartment": "Shoe Compartment",
                "Zip Closure": "Zip Closure",
                "Back Strap": "Back Strap",
                "Padded Handles": "Padded Handles",
                "Shoulder Strap": "Shoulder Strap"
            }
        },
        "special_features3": {
            "definition": "Select the value(s) that best describe(s) the product's special feature(s) in order of importance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Expandable\n",
            "label": "Special Feature(s) (special_features3)",
            "amazon_recommendation": {
                "Back Padding": "Back Padding",
                "Chest Strap": "Chest Strap",
                "Earphone Cord Support": "Earphone Cord Support",
                "Expandable": "Expandable",
                "Fits DIN A4": "Fits DIN A4",
                "Fits Letter": "Fits Letter",
                "Helmet Compartment": "Helmet Compartment",
                "Hip Belt": "Hip Belt",
                "Hydration": "Hydration",
                "Integrated Rain Cover": "Integrated Rain Cover",
                "Laptop Compartment": "Laptop Compartment",
                "Snowboard Strap": "Snowboard Strap",
                "Telescope Handle": "Telescope Handle",
                "Water Bottle / Umbrella Pouch": "Water Bottle / Umbrella Pouch",
                "Wheels": "Wheels",
                "360 Degree Rotating Wheel": "360 Degree Rotating Wheel",
                "Inner Mesh Pocket": "Inner Mesh Pocket",
                "Laundry Compartment": "Laundry Compartment",
                "Packing Straps": "Packing Straps",
                "Shirt Compartment": "Shirt Compartment",
                "Shoe Compartment": "Shoe Compartment",
                "Zip Closure": "Zip Closure",
                "Back Strap": "Back Strap",
                "Padded Handles": "Padded Handles",
                "Shoulder Strap": "Shoulder Strap"
            }
        },
        "special_features4": {
            "definition": "Select the value(s) that best describe(s) the product's special feature(s) in order of importance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Expandable\n",
            "label": "Special Feature(s) (special_features4)",
            "amazon_recommendation": {
                "Back Padding": "Back Padding",
                "Chest Strap": "Chest Strap",
                "Earphone Cord Support": "Earphone Cord Support",
                "Expandable": "Expandable",
                "Fits DIN A4": "Fits DIN A4",
                "Fits Letter": "Fits Letter",
                "Helmet Compartment": "Helmet Compartment",
                "Hip Belt": "Hip Belt",
                "Hydration": "Hydration",
                "Integrated Rain Cover": "Integrated Rain Cover",
                "Laptop Compartment": "Laptop Compartment",
                "Snowboard Strap": "Snowboard Strap",
                "Telescope Handle": "Telescope Handle",
                "Water Bottle / Umbrella Pouch": "Water Bottle / Umbrella Pouch",
                "Wheels": "Wheels",
                "360 Degree Rotating Wheel": "360 Degree Rotating Wheel",
                "Inner Mesh Pocket": "Inner Mesh Pocket",
                "Laundry Compartment": "Laundry Compartment",
                "Packing Straps": "Packing Straps",
                "Shirt Compartment": "Shirt Compartment",
                "Shoe Compartment": "Shoe Compartment",
                "Zip Closure": "Zip Closure",
                "Back Strap": "Back Strap",
                "Padded Handles": "Padded Handles",
                "Shoulder Strap": "Shoulder Strap"
            }
        },
        "special_features5": {
            "definition": "Select the value(s) that best describe(s) the product's special feature(s) in order of importance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Expandable\n",
            "label": "Special Feature(s) (special_features5)",
            "amazon_recommendation": {
                "Back Padding": "Back Padding",
                "Chest Strap": "Chest Strap",
                "Earphone Cord Support": "Earphone Cord Support",
                "Expandable": "Expandable",
                "Fits DIN A4": "Fits DIN A4",
                "Fits Letter": "Fits Letter",
                "Helmet Compartment": "Helmet Compartment",
                "Hip Belt": "Hip Belt",
                "Hydration": "Hydration",
                "Integrated Rain Cover": "Integrated Rain Cover",
                "Laptop Compartment": "Laptop Compartment",
                "Snowboard Strap": "Snowboard Strap",
                "Telescope Handle": "Telescope Handle",
                "Water Bottle / Umbrella Pouch": "Water Bottle / Umbrella Pouch",
                "Wheels": "Wheels",
                "360 Degree Rotating Wheel": "360 Degree Rotating Wheel",
                "Inner Mesh Pocket": "Inner Mesh Pocket",
                "Laundry Compartment": "Laundry Compartment",
                "Packing Straps": "Packing Straps",
                "Shirt Compartment": "Shirt Compartment",
                "Shoe Compartment": "Shoe Compartment",
                "Zip Closure": "Zip Closure",
                "Back Strap": "Back Strap",
                "Padded Handles": "Padded Handles",
                "Shoulder Strap": "Shoulder Strap"
            }
        },
        "seasons": {
            "definition": "Select the value that best describes the season associated with the product.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Autumn/Winter\n",
            "label": "Season"
        },
        "lifestyle": {
            "definition": "Specify the lifestyle associated with the product.\n",
            "accepted_value": "A text string up to 50 characters.\n",
            "example": "Casual\n",
            "label": "Lifestyle (lifestyle)"
        },
        "lining_description": {
            "definition": "",
            "accepted_value": "Provide the description of the lining.",
            "example": "Mesh",
            "label": "Lining Description"
        },
        "pattern_name": {
            "definition": "Select the value that best describe the product's pattern in order of prominence.",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Checkered\n",
            "label": "Pattern"
        },
        "is_stain_resistant": {
            "definition": "Select \"True\" if the product is stain resistant or \"False\" if the product is not stain resistant. The default is \"False\".\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "False\n",
            "label": "Stain Resistant?"
        },
        "team_name": {
            "definition": "",
            "accepted_value": "",
            "example": "",
            "label": "team_name"
        },
        "wheel_type": {
            "definition": "",
            "accepted_value": "Provide the type of wheel or wheels the item has",
            "example": "Inline, No Wheels",
            "label": "Wheel Type"
        },
        "packer_contact_information": {
            "definition": "",
            "accepted_value": "If Packer is different from manufacturer, provide address and contact number of the packer. If manufacturer is the packer, provide address and contact number of the manufacturer.",
            "example": "ABC Packers, Industrial Estate, Mumbai, India. Customer Care 000 999 999999",
            "label": "Packer (packer_contact_information)"
        },
        "item_type_name": {
            "definition": "Describes  the type of Item",
            "accepted_value": "Provide the generic name for you Product (example For 'Vaseline' Item Type name is 'Petroleum Jelly').",
            "example": "Petroleum Jelly",
            "label": "Item Type Name (item_type_name)"
        },
        "importer_contact_information": {
            "definition": "",
            "accepted_value": "Provide the product's importer name, address and contact number.",
            "example": "ABC Importers, Industrial Estate, Mumbai, India. Customer Care 000 999 999999",
            "label": "Importer (importer_contact_information)"
        },
        "wear_resistance": {
            "definition": "Select the value that best describes the product's scratch resistance. This information will surface in the Product Specifications widget on the detail page.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Wearproof",
            "label": "Scratch Resistance"
        },
        "handle_type": {
            "definition": "Select the value that best describes the product's handle type. This information will surface in the Product Specifications widget on the detail page.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Extendable",
            "label": "Handle Type"
        },
        "number_of_pockets": {
            "definition": "",
            "accepted_value": "Provide the number of pockets in the item",
            "example": 12,
            "label": "Number of Pockets"
        },
        "shaft_style_type": {
            "definition": "Use to specify the shaft type for lacrosse shaft products",
            "accepted_value": "Please refer to the Valid values tab for the appropriate value for this field.",
            "example": "attacker-shafts",
            "label": "Shaft Type (shaft_style_type)"
        },
        "recommended_uses_for_product1": {
            "definition": "Specify if the product is designed for indoor or outdoor use. Select from the list of valid values.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Indoor Use Only",
            "label": "Recommended Uses For Product"
        },
        "recommended_uses_for_product2": {
            "definition": "Specify if the product is designed for indoor or outdoor use. Select from the list of valid values.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Indoor Use Only",
            "label": "Recommended Uses For Product"
        },
        "recommended_uses_for_product3": {
            "definition": "Specify if the product is designed for indoor or outdoor use. Select from the list of valid values.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Indoor Use Only",
            "label": "Recommended Uses For Product"
        },
        "recommended_uses_for_product4": {
            "definition": "Specify if the product is designed for indoor or outdoor use. Select from the list of valid values.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Indoor Use Only",
            "label": "Recommended Uses For Product"
        },
        "recommended_uses_for_product5": {
            "definition": "Specify if the product is designed for indoor or outdoor use. Select from the list of valid values.",
            "accepted_value": "Select from the list of valid values.",
            "example": "Indoor Use Only",
            "label": "Recommended Uses For Product"
        },
        "pattern_type": {
            "definition": "Describes the pattern on the product.",
            "accepted_value": "Describes the pattern on the product.",
            "example": "Checked",
            "label": "Pattern Type"
        },
        "special_size_type": {
            "definition": "",
            "accepted_value": "",
            "example": "",
            "label": "special_size_type"
        }
    },
    "Product Enrichment": {
        "strap_type": {
            "definition": "Select the value that best describes the product's strap type. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Adjustable\n",
            "label": "Strap Type (strap_type)",
            "amazon_recommendation": {
                "Adjustable": "Adjustable",
                "Back": "Back",
                "Chain": "Chain",
                "Convertible": "Convertible",
                "Cross Body": "Cross Body",
                "Double Handle": "Double Handle",
                "Handle": "Handle",
                "Padded": "Padded",
                "Removable": "Removable",
                "Shoulder": "Shoulder",
                "Single": "Single",
                "Slingback": "Slingback",
                "Waist": "Waist"
            }
        },
        "_3d_technology": {
            "definition": "",
            "accepted_value": "",
            "example": "",
            "label": "_3d_technology (_3d_technology)"
        },
        "net_content_count_unit_of_measure": {
            "definition": "",
            "accepted_value": "Provide the unit associated with the net content count value provided",
            "example": "Bag, Box, Sheet",
            "label": "Net Content Count Unit"
        },
        "net_content_count": {
            "definition": "",
            "accepted_value": "Provide the numerical net content of the item expressed as a count",
            "example": 1,
            "label": "Net Content Count"
        },
        "golf_club_flex": {
            "definition": "For Golf products where relevant, please specify Flex",
            "accepted_value": "Please refer to the Valid values tab for the appropriate value for this field.",
            "example": "regular",
            "label": "Golf Flex"
        }
    },
    "Dimensions": {
        "website_shipping_weight": {
            "definition": "Specify the product's shipping weight. You must provide a Shipping Weight Unit of Measure if you provide a value for Shipping Weight.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": "10\n",
            "label": "Shipping Weight (website_shipping_weight)"
        },
        "website_shipping_weight_unit_of_measure": {
            "definition": "Select a unit of measure for Shipping Weight. You must provide a Shipping Weight Unit of Measure if you provided a value for Shipping Weight.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "KG",
            "label": "Shipping Weight Unit of Measure (website_shipping_weight_unit_of_measure)",
            "amazon_recommendation": {
                "LB": "LB",
                "KG": "KG",
                "GR": "GR",
                "Hundredths Pounds": "Hundredths Pounds",
                "MG": "MG",
                "Tons": "Tons",
                "OZ": "OZ"
            }
        },
        "item_weight": {
            "definition": "The weight of the product.",
            "accepted_value": "Provide the weight of the product.",
            "example": 5,
            "label": "Item Weight (item_weight)"
        },
        "item_weight_unit_of_measure": {
            "definition": "The unit of measure used to describe the weight of the product without shipping material.",
            "accepted_value": "Specify the unit of measure used to describe the weight of the product without shipping material.",
            "example": "GR, KG",
            "label": "Item Weight Unit of Measure (item_weight_unit_of_measure)",
            "amazon_recommendation": {
                "LB": "LB",
                "KG": "KG",
                "GR": "GR",
                "Hundredths Pounds": "Hundredths Pounds",
                "MG": "MG",
                "Tons": "Tons",
                "OZ": "OZ"
            }
        },
        "item_volume": {
            "definition": "Indicates the volume capacity of a product.",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Please do not use commas.",
            "example": "34.78",
            "label": "Item Volume (item_volume)"
        },
        "item_volume_unit_of_measure": {
            "definition": "Indicates the volume capacity of a product.",
            "accepted_value": "Indicates the volume capacity of a product.",
            "example": "milliliters",
            "label": "Item Volume Unit Of Measure (item_volume_unit_of_measure)",
            "amazon_recommendation": {
                "cup": "cup",
                "microliters": "microliters",
                "cubic_feet": "cubic_feet",
                "centiliters": "centiliters",
                "Gallons": "Gallons",
                "Quarts": "Quarts",
                "nanoliters": "nanoliters",
                "Liters": "Liters",
                "picoliters": "picoliters",
                "Unknown modifier": "Unknown modifier",
                "Fluid Ounces": "Fluid Ounces",
                "cubic_meters": "cubic_meters",
                "Pints": "Pints",
                "cubic_yards": "cubic_yards",
                "cubic-in": "cubic-in",
                "imperial_gallons": "imperial_gallons",
                "Milliliters": "Milliliters",
                "cubic_centimeters": "cubic_centimeters",
                "deciliters": "deciliters"
            }
        },
        "size_map": {
            "definition": "This attribute is normalized with controlled values in some cases. Unrecognized values may be dropped in the pipeline. It is not clear where this normalization exists.",
            "accepted_value": "Specify the value or values that best correspond to the size of the item",
            "example": "small",
            "label": "Size Map (size_map)",
            "amazon_recommendation": {
                "Large": "Large",
                "Medium": "Medium",
                "Small": "Small",
                "X-Large": "X-Large",
                "X-Small": "X-Small",
                "XX-Large": "XX-Large",
                "XX-Small": "XX-Small",
                "XXX-Large": "XXX-Large",
                "XXX-Small": "XXX-Small",
                "XXXX-Large": "XXXX-Large",
                "XXXX-Small": "XXXX-Small",
                "XXXXX-Large": "XXXXX-Large",
                "XXXXX-Small": "XXXXX-Small"
            }
        },
        "strap_length_unit_of_measure": {
            "definition": "",
            "accepted_value": "Select the corresponding unit",
            "example": "Millimeters,Centimeters",
            "label": "Strap Length unit of measure"
        },
        "strap_length": {
            "definition": "",
            "accepted_value": "Measurement of the entire length of a strap attached to an item, from end to end.",
            "example": "16 inches, 30 centimeters",
            "label": "Strap Length"
        }
    },
    "Fulfillment": {
        "fulfillment_center_id": {
            "definition": "\"Amazon-fulfilled products: For those merchants using Amazon fulfilment services, this designates which fulfilment network will be used. Specifying a value other than ‘DEFAULT’ for fulfillment_center_id will cancel the Merchant-fulfilled offering.  Resubmitting with a blank or ‘DEFAULT’ value for the fulfillment_center_id, along with quantity, will switch the item back to Merchant-fulfilled.  \nMerchant-fulfilled products: Do not enter fulfillment_center_id, as it is not applicable.\"\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "AMAZON_IN",
            "label": "Fulfillment Center ID (fulfillment_center_id)",
            "amazon_recommendation": {
                "AMAZON_IN": "AMAZON_IN",
                "DEFAULT": "DEFAULT"
            }
        },
        "package_weight": {
            "definition": "Specify the weight of the item's package. You must provide an Item Package Weight Unit of Measure if you provide a value for Item Package Weight.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": "5\n",
            "label": "Item Package Weight (package_weight)"
        },
        "package_weight_unit_of_measure": {
            "definition": "Select a unit of measure for Item Package Weight. You must provide an Item Package Weight Unit of Measure if you provided a value for Item Package Weight.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "KG",
            "label": "Item Package Weight Unit of Measure (package_weight_unit_of_measure)",
            "amazon_recommendation": {
                "LB": "LB",
                "KG": "KG",
                "GR": "GR",
                "Hundredths Pounds": "Hundredths Pounds",
                "MG": "MG",
                "Tons": "Tons",
                "OZ": "OZ"
            }
        },
        "package_length": {
            "definition": "Specify the length of the item's package. You must provide an Item Package Length Unit of Measure if you provide a value for Item Package Length.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": 10,
            "label": "Item Package Length (package_length)"
        },
        "package_dimensions_unit_of_measure": {
            "definition": "The unit of measure used to describe the dimensions (width, length, height) of the package.",
            "accepted_value": "Select a value from the list of valid values.",
            "example": "Centimeters",
            "label": "Package Dimensions Unit Of Measure"
        },
        "package_length_unit_of_measure": {
            "definition": "Select a unit of measure for Item Package Length. You must provide an Item Package Length Unit of Measure if you provided a value for Item Package Length.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Inches\n",
            "label": "Item Package Length Unit of Measure (package_length_unit_of_measure)",
            "amazon_recommendation": {
                "Angstrom": "Angstrom",
                "Mils": "Mils",
                "Yards": "Yards",
                "Picometre": "Picometre",
                "Miles": "Miles",
                "Decimetres": "Decimetres",
                "Millimeters": "Millimeters",
                "Meters": "Meters",
                "Inches": "Inches",
                "Feet": "Feet",
                "Centimeters": "Centimeters",
                "Hundredths-Inches": "Hundredths-Inches",
                "Nanometre": "Nanometre",
                "µM": "µM",
                "Kilometres": "Kilometres"
            }
        },
        "package_width": {
            "definition": "Specify the width of the item's package. You must provide an Item Package Width Unit of Measure if you provide a value for Item Package Width.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": "10\n",
            "label": "Item Package Width (package_width)"
        },
        "package_width_unit_of_measure": {
            "definition": "Select a unit of measure for Item Package Width. You must provide an Item Package Width Unit of Measure if you provided a value for Item Package Width.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Inches\n",
            "label": "Item Package Width Unit of Measure (package_width_unit_of_measure)",
            "amazon_recommendation": {
                "Angstrom": "Angstrom",
                "Mils": "Mils",
                "Yards": "Yards",
                "Picometre": "Picometre",
                "Miles": "Miles",
                "Decimetres": "Decimetres",
                "Millimeters": "Millimeters",
                "Meters": "Meters",
                "Inches": "Inches",
                "Feet": "Feet",
                "Centimeters": "Centimeters",
                "Hundredths-Inches": "Hundredths-Inches",
                "Nanometre": "Nanometre",
                "µM": "µM",
                "Kilometres": "Kilometres"
            }
        },
        "package_height": {
            "definition": "Specify the height of the item's package. You must provide an Item Package Height Unit of Measure if you provide a value for Item Package Height.\n",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Commas are not accepted.\n",
            "example": 10,
            "label": "Item Package Height (package_height)"
        },
        "package_height_unit_of_measure": {
            "definition": "Select a unit of measure for Item Package Height. You must provide an Item Package Height Unit of Measure if you provided a value for Item Package Height.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Inches\n",
            "label": "Item Package Height Unit of Measure (package_height_unit_of_measure)",
            "amazon_recommendation": {
                "Angstrom": "Angstrom",
                "Mils": "Mils",
                "Yards": "Yards",
                "Picometre": "Picometre",
                "Miles": "Miles",
                "Decimetres": "Decimetres",
                "Millimeters": "Millimeters",
                "Meters": "Meters",
                "Inches": "Inches",
                "Feet": "Feet",
                "Centimeters": "Centimeters",
                "Hundredths-Inches": "Hundredths-Inches",
                "Nanometre": "Nanometre",
                "µM": "µM",
                "Kilometres": "Kilometres"
            }
        }
    },
    "Compliance": {
        "legal_disclaimer_description": {
            "definition": "Specify legal information associated with the product.\n",
            "accepted_value": "A text string up to 1,000 characters.\n",
            "example": "The customer is responsible for checking local laws before purchasing this product.\n",
            "label": "Legal Disclaimer (legal_disclaimer_description)"
        },
        "safety_warning": {
            "definition": "Specify safety issues associated with the product.\n",
            "accepted_value": "A text string up to 1,000 characters.\n",
            "example": "Choking Hazard\n",
            "label": "Safety Warning (safety_warning)"
        },
        "batteries_required": {
            "definition": "An internal rechargeable, non-replaceable battery (e.g. laptop battery) is also considered a battery.",
            "accepted_value": "Select: true or false.",
            "example": "FALSE",
            "label": "Is this product a battery or does it use batteries? (batteries_required)",
            "amazon_recommendation": {
                "Yes": "Yes",
                "No": "No"
            }
        },
        "are_batteries_included": {
            "definition": "Select \"yes\" if batteries are contained in the product (e.g. batteries inside a pair of Bluetooth headphones) and/or included with the product (e.g. batteries packed separately with a camera), otherwise select \"no\"",
            "accepted_value": "Select: true or false.",
            "example": "TRUE",
            "label": "Batteries are Included (are_batteries_included)",
            "amazon_recommendation": {
                "Yes": "Yes",
                "No": "No"
            }
        },
        "number_of_batteries1": {
            "definition": "Number of batteries, including spares (total batteries, not cells per battery)",
            "accepted_value": "Any integer greater than or equal to one",
            "example": 3,
            "label": "Number of batteries (number_of_batteries1)"
        },
        "number_of_batteries2": {
            "definition": "Number of batteries, including spares (total batteries, not cells per battery)",
            "accepted_value": "Any integer greater than or equal to one",
            "example": 3,
            "label": "Number of batteries (number_of_batteries2)"
        },
        "number_of_batteries3": {
            "definition": "Number of batteries, including spares (total batteries, not cells per battery)",
            "accepted_value": "Any integer greater than or equal to one",
            "example": 3,
            "label": "Number of batteries (number_of_batteries3)"
        },
        "battery_type1": {
            "definition": "Specify the type of battery in the product.",
            "accepted_value": "Please refer to the Valid Values worksheet.  Only use this when PowerSource is 'battery'",
            "example": "battery_type_lithium_ion",
            "label": "Battery type/size (battery_type1)",
            "amazon_recommendation": {
                "battery_type_aaa": "battery_type_aaa",
                "battery_type_p76": "battery_type_p76",
                "battery_type_product_specific": "battery_type_product_specific",
                "lithium_polymer": "lithium_polymer",
                "battery_type_lithium_metal": "battery_type_lithium_metal",
                "battery_type_cr123a": "battery_type_cr123a",
                "battery_type_9v": "battery_type_9v",
                "battery_type_aa": "battery_type_aa",
                "battery_type_lithium_ion": "battery_type_lithium_ion",
                "battery_type_a": "battery_type_a",
                "battery_type_cr2": "battery_type_cr2",
                "battery_type_c": "battery_type_c",
                "battery_type_d": "battery_type_d",
                "battery_type_cr5": "battery_type_cr5",
                "AAAA Battery": "AAAA Battery",
                "12v Battery": "12v Battery"
            }
        },
        "battery_type2": {
            "definition": "Specify the type of battery in the product.",
            "accepted_value": "Please refer to the Valid Values worksheet.  Only use this when PowerSource is 'battery'",
            "example": "battery_type_lithium_ion",
            "label": "Battery type/size (battery_type2)",
            "amazon_recommendation": {
                "battery_type_aaa": "battery_type_aaa",
                "battery_type_p76": "battery_type_p76",
                "battery_type_product_specific": "battery_type_product_specific",
                "lithium_polymer": "lithium_polymer",
                "battery_type_lithium_metal": "battery_type_lithium_metal",
                "battery_type_cr123a": "battery_type_cr123a",
                "battery_type_9v": "battery_type_9v",
                "battery_type_aa": "battery_type_aa",
                "battery_type_lithium_ion": "battery_type_lithium_ion",
                "battery_type_a": "battery_type_a",
                "battery_type_cr2": "battery_type_cr2",
                "battery_type_c": "battery_type_c",
                "battery_type_d": "battery_type_d",
                "battery_type_cr5": "battery_type_cr5",
                "AAAA Battery": "AAAA Battery",
                "12v Battery": "12v Battery"
            }
        },
        "battery_type3": {
            "definition": "Specify the type of battery in the product.",
            "accepted_value": "Please refer to the Valid Values worksheet.  Only use this when PowerSource is 'battery'",
            "example": "battery_type_lithium_ion",
            "label": "Battery type/size (battery_type3)",
            "amazon_recommendation": {
                "battery_type_aaa": "battery_type_aaa",
                "battery_type_p76": "battery_type_p76",
                "battery_type_product_specific": "battery_type_product_specific",
                "lithium_polymer": "lithium_polymer",
                "battery_type_lithium_metal": "battery_type_lithium_metal",
                "battery_type_cr123a": "battery_type_cr123a",
                "battery_type_9v": "battery_type_9v",
                "battery_type_aa": "battery_type_aa",
                "battery_type_lithium_ion": "battery_type_lithium_ion",
                "battery_type_a": "battery_type_a",
                "battery_type_cr2": "battery_type_cr2",
                "battery_type_c": "battery_type_c",
                "battery_type_d": "battery_type_d",
                "battery_type_cr5": "battery_type_cr5",
                "AAAA Battery": "AAAA Battery",
                "12v Battery": "12v Battery"
            }
        },
        "battery_cell_composition": {
            "definition": "Commonly printed on the battery or outer packaging",
            "accepted_value": "Select a value from the list of valid values.",
            "example": "Lithium Ion",
            "label": "Battery composition (battery_cell_composition)",
            "amazon_recommendation": {
                "Polymer": "Polymer",
                "Lithium Ion": "Lithium Ion",
                "Silver Oxide": "Silver Oxide",
                "Zinc Chloride": "Zinc Chloride",
                "Alkaline": "Alkaline",
                "Lithium Manganese Dioxide": "Lithium Manganese Dioxide",
                "Nickel Oxyhydroxide": "Nickel Oxyhydroxide",
                "Zinc Carbon": "Zinc Carbon",
                "Manganese": "Manganese",
                "Lithium Polymer": "Lithium Polymer",
                "Sealed Lead Acid": "Sealed Lead Acid",
                "Lithium Metal": "Lithium Metal",
                "NiCAD": "NiCAD",
                "Lithium": "Lithium",
                "Lead Calcium": "Lead Calcium",
                "NiMh": "NiMh",
                "Aluminum Oxygen": "Aluminum Oxygen",
                "Mercury Oxide": "Mercury Oxide",
                "Zinc": "Zinc",
                "Lead Acid": "Lead Acid"
            }
        },
        "lithium_battery_weight": {
            "definition": "Commonly found in manufacturer's technical information",
            "accepted_value": "A number with up to 10 digits to the left of the decimal point and 2 digits to the right of the decimal point. Please do not use commas.",
            "example": "0.9",
            "label": "Lithium content (lithium_battery_weight)"
        },
        "lithium_battery_weight_unit_of_measure": {
            "definition": "Select the unit of measure for Lithium Battery Weight  . If Lithium Battery Weight  is used, you must also enter theLithium Battery Weight  Unit Of Measure.",
            "accepted_value": "Select the unit of measure for Lithium Battery Weight  . If Lithium Battery Weight  is used, you must also enter theLithium Battery Weight  Unit Of Measure.",
            "example": "grams",
            "label": "Lithium Battery Weight Unit Of Measure (lithium_battery_weight_unit_of_measure)",
            "amazon_recommendation": {
                "LB": "LB",
                "KG": "KG",
                "GR": "GR",
                "Hundredths Pounds": "Hundredths Pounds",
                "MG": "MG",
                "Tons": "Tons",
                "OZ": "OZ"
            }
        },
        "battery_weight": {
            "definition": "Provide the total net weight of the batteries included. This is the weight of the standalone batteries not including packaging or the device it may be used in.",
            "accepted_value": "Number up to 10 digits and 2 decimal points long.",
            "example": 150,
            "label": "Battery weight (battery_weight)"
        },
        "battery_weight_unit_of_measure": {
            "definition": "Unit of measure used to describe the battery weight",
            "accepted_value": "Unit of measure used to describe the battery weight",
            "example": "gr or kg",
            "label": "Battery Weight Unit of Measure (battery_weight_unit_of_measure)",
            "amazon_recommendation": {
                "LB": "LB",
                "KG": "KG",
                "GR": "GR",
                "Hundredths Pounds": "Hundredths Pounds",
                "MG": "MG",
                "Tons": "Tons",
                "OZ": "OZ"
            }
        },
        "number_of_lithium_metal_cells": {
            "definition": "Total number of Lithium cells (of type Metal) in the product,  where the cell isn't contained in an encased battery.",
            "accepted_value": "Total number of Lithium cells (of type Metal) in the product,  where the cell isn't contained in an encased battery.",
            "example": "1, 2, 3",
            "label": "Number of Lithium Metal Cells (number_of_lithium_metal_cells)"
        },
        "number_of_lithium_ion_cells": {
            "definition": "Total number of Lithium cells (of type Ion) in the product,  where the cell isn't contained in an encased battery.",
            "accepted_value": "Total number of Lithium cells (of type Ion) in the product,  where the cell isn't contained in an encased battery.",
            "example": "1, 2, 3",
            "label": "Number of Lithium-ion Cells (number_of_lithium_ion_cells)"
        },
        "lithium_battery_packaging": {
            "definition": "“Batteries only”: the battery or batteries are sold alone or with items they are not meant to power. “With equipment”: the battery is included in the package but not pre-installed. “In equipment”: the battery is pre-installed in the item.",
            "accepted_value": "Choices are batteries_only (If battery is a standalone) batteries_contained_in_equipment (if battery is assembled in the item) or batteries_packed_with_equipment (If battery is included in the item packaging but not assembled in the item)",
            "example": "batteries_contained_in_equipment",
            "label": "Lithium Battery Packaging (lithium_battery_packaging)",
            "amazon_recommendation": {
                "batteries_packed_with_equipment": "batteries_packed_with_equipment",
                "batteries_contained_in_equipment": "batteries_contained_in_equipment",
                "batteries_only": "batteries_only"
            }
        },
        "lithium_battery_energy_content": {
            "definition": "Commonly printed on the battery or outer packaging",
            "accepted_value": "Watt hrs of each battery (or cell) in unit",
            "example": "1, 2, 3",
            "label": "Watt hours per battery (lithium_battery_energy_content)"
        },
        "lithium_battery_energy_content_unit_of_measure": {
            "definition": "Indicate unit of measure if Lithium Battery Energy Content is populated",
            "accepted_value": "Indicate unit of measure if Lithium Battery Energy Content is populated",
            "example": "watts-hora",
            "label": "Lithium Battery Energy Content Unit Of Measure (lithium_battery_energy_content_unit_of_measure)",
            "amazon_recommendation": {
                "Kilowatt Hours": "Kilowatt Hours",
                "Joules": "Joules",
                "Cubic Feet": "Cubic Feet",
                "Watt Hours": "Watt Hours",
                "Milliampere Hour (mAh)": "Milliampere Hour (mAh)",
                "Cubic Meters": "Cubic Meters",
                "Milliamp Hours (mAh)": "Milliamp Hours (mAh)",
                "Milliampere Second (mAs)": "Milliampere Second (mAs)",
                "British Thermal Units (BTUs)": "British Thermal Units (BTUs)"
            }
        },
        "supplier_declared_dg_hz_regulation1": {
            "definition": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "accepted_value": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "example": "Transportation,Storage,Waste",
            "label": "Applicable Dangerous Goods Regulations (supplier_declared_dg_hz_regulation1)",
            "amazon_recommendation": {
                "GHS": "GHS",
                "Unknown": "Unknown",
                "Other": "Other",
                "Not Applicable": "Not Applicable",
                "Transportation": "Transportation",
                "Waste": "Waste",
                "Storage": "Storage"
            }
        },
        "supplier_declared_dg_hz_regulation2": {
            "definition": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "accepted_value": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "example": "Transportation,Storage,Waste",
            "label": "Applicable Dangerous Goods Regulations (supplier_declared_dg_hz_regulation2)",
            "amazon_recommendation": {
                "GHS": "GHS",
                "Unknown": "Unknown",
                "Other": "Other",
                "Not Applicable": "Not Applicable",
                "Transportation": "Transportation",
                "Waste": "Waste",
                "Storage": "Storage"
            }
        },
        "supplier_declared_dg_hz_regulation3": {
            "definition": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "accepted_value": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "example": "Transportation,Storage,Waste",
            "label": "Applicable Dangerous Goods Regulations (supplier_declared_dg_hz_regulation3)",
            "amazon_recommendation": {
                "GHS": "GHS",
                "Unknown": "Unknown",
                "Other": "Other",
                "Not Applicable": "Not Applicable",
                "Transportation": "Transportation",
                "Waste": "Waste",
                "Storage": "Storage"
            }
        },
        "supplier_declared_dg_hz_regulation4": {
            "definition": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "accepted_value": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "example": "Transportation,Storage,Waste",
            "label": "Applicable Dangerous Goods Regulations (supplier_declared_dg_hz_regulation4)",
            "amazon_recommendation": {
                "GHS": "GHS",
                "Unknown": "Unknown",
                "Other": "Other",
                "Not Applicable": "Not Applicable",
                "Transportation": "Transportation",
                "Waste": "Waste",
                "Storage": "Storage"
            }
        },
        "supplier_declared_dg_hz_regulation5": {
            "definition": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "accepted_value": "Select the purposes for which your product is regulated as a dangerous good or hazardous material (hazmat) (e.g. dangerous good for transportation, hazmat for waste). Common products can be regulated as dangerous goods (e.g. nail polish, aerosols, cleaning products). For more information, refer to the help pages.",
            "example": "Transportation,Storage,Waste",
            "label": "Applicable Dangerous Goods Regulations (supplier_declared_dg_hz_regulation5)",
            "amazon_recommendation": {
                "GHS": "GHS",
                "Unknown": "Unknown",
                "Other": "Other",
                "Not Applicable": "Not Applicable",
                "Transportation": "Transportation",
                "Waste": "Waste",
                "Storage": "Storage"
            }
        },
        "hazmat_united_nations_regulatory_id": {
            "definition": "Usually found in the Safety Data Sheet or procured from the manufacturer/distributor. For more information, refer to the help pages.",
            "accepted_value": "Hazmat United Nationals Regulatory ID",
            "example": "UN1950",
            "label": "UN number (hazmat_united_nations_regulatory_id)"
        },
        "safety_data_sheet_url": {
            "definition": "Can be procured from the manufacturer/distributor. For more information, refer to the help pages.",
            "accepted_value": "Holds link to the externally hosted SDS (Safety Data Sheet) for an item",
            "example": "http://www.sigmaaldrich.com/MSDS/MSDS/DisplayMSDSPage.do?country=US&language=en&productNumber=84985&brand=ALDRICH&PageToGoToURL=http%3A%2F%2Fwww.sigmaaldrich.com%2Fcatalog%2Fproduct%2Faldrich%2F84985%3Flang%3Den",
            "label": "Safety Data Sheet (SDS) URL (safety_data_sheet_url)"
        },
        "flash_point": {
            "definition": "Commonly found in Safety Data Sheet or provided by manufacturer. For more information, refer to the help pages.",
            "accepted_value": "A number with up to 10 digits allowed to the left of the decimal point and 2 digits to the right of the decimal point. Please do not use commas.",
            "example": 180,
            "label": "Flash point (°C)? (flash_point)"
        },
        "fabric_type1": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type2": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type3": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type4": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type5": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type6": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type7": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type8": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type9": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "fabric_type10": {
            "definition": "",
            "accepted_value": "List all fabrics separated by ','. Indicate with % composition. Always add \"Viscose\" or \"Rayon\" instead of \"Bamboo\", and \"Azlon\" for Soy",
            "example": "60% Cotton, 40% Polyester",
            "label": "Fabric Type"
        },
        "ghs_classification_class1": {
            "definition": "Commonly found in the Safety Data Sheet or on the product packaging.",
            "accepted_value": "Global Harmonized System (GHS) CLP classification system.",
            "example": "[{class:Explosive,subcategory:Liquid}]",
            "label": "Categorisation/GHS pictograms (select all that apply) (ghs_classification_class1)",
            "amazon_recommendation": {
                "Explosive": "Explosive",
                "Flammable": "Flammable",
                "Oxidizing": "Oxidizing",
                "Compressed Gas": "Compressed Gas",
                "Corrosive": "Corrosive",
                "Toxic": "Toxic",
                "Irritant": "Irritant",
                "Health Hazard": "Health Hazard",
                "Environmentally Damaging": "Environmentally Damaging",
                "Amazon Specific No Label With Warning": "Amazon Specific No Label With Warning"
            }
        },
        "ghs_classification_class2": {
            "definition": "Commonly found in the Safety Data Sheet or on the product packaging.",
            "accepted_value": "Global Harmonized System (GHS) CLP classification system.",
            "example": "[{class:Explosive,subcategory:Liquid}]",
            "label": "Categorisation/GHS pictograms (select all that apply) (ghs_classification_class2)",
            "amazon_recommendation": {
                "Explosive": "Explosive",
                "Flammable": "Flammable",
                "Oxidizing": "Oxidizing",
                "Compressed Gas": "Compressed Gas",
                "Corrosive": "Corrosive",
                "Toxic": "Toxic",
                "Irritant": "Irritant",
                "Health Hazard": "Health Hazard",
                "Environmentally Damaging": "Environmentally Damaging",
                "Amazon Specific No Label With Warning": "Amazon Specific No Label With Warning"
            }
        },
        "ghs_classification_class3": {
            "definition": "Commonly found in the Safety Data Sheet or on the product packaging.",
            "accepted_value": "Global Harmonized System (GHS) CLP classification system.",
            "example": "[{class:Explosive,subcategory:Liquid}]",
            "label": "Categorisation/GHS pictograms (select all that apply) (ghs_classification_class3)",
            "amazon_recommendation": {
                "Explosive": "Explosive",
                "Flammable": "Flammable",
                "Oxidizing": "Oxidizing",
                "Compressed Gas": "Compressed Gas",
                "Corrosive": "Corrosive",
                "Toxic": "Toxic",
                "Irritant": "Irritant",
                "Health Hazard": "Health Hazard",
                "Environmentally Damaging": "Environmentally Damaging",
                "Amazon Specific No Label With Warning": "Amazon Specific No Label With Warning"
            }
        }
    },
    "Offer": {
        "condition_type": {
            "definition": "Select the value that best describes the product's condition.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "New\n",
            "label": "Condition (condition_type)",
            "amazon_recommendation": {
                "Collectible - Very Good": "Collectible - Very Good",
                "Used - Good": "Used - Good",
                "Club": "Club",
                "Collectible - Acceptable": "Collectible - Acceptable",
                "Collectible - Like New": "Collectible - Like New",
                "Used - Like New": "Used - Like New",
                "Collectible - Good": "Collectible - Good",
                "New": "New",
                "Used - Very Good": "Used - Very Good",
                "Used - Acceptable": "Used - Acceptable"
            }
        },
        "condition_note": {
            "definition": "Descriptive text explaining the item’s condition. This Condition Note field is limited to 1000 characters for all Used, Collectible, and Refurbished items. Condition notes are not accepted for products with a condition type of new.",
            "accepted_value": "A text string with a maximum of 1,000 characters. The Condition Note field doesn’t allow special characters.",
            "example": "New\n",
            "label": "Condition Note (condition_note)"
        },
        "currency": {
            "definition": "The currency code for price data.  This is required if map, msrp, Standard Price, or sale_price is provided.  The same currency will apply to all price-related fields.",
            "accepted_value": "Specify the product's unique 3 letter currency code.\n",
            "example": "INR",
            "label": "Currency"
        },
        "fulfillment_latency": {
            "definition": "This is time in which you will be able to ship the product after you get an order. The default value is 1 to 2 days. Use this field if your handling time is greater than 2 business days.",
            "accepted_value": "This is time in which you will be able to ship the product after you get an order. The default value is 1 to 2 days. Use this field if your handling time is greater than 2 business days.",
            "example": 3,
            "label": "Handling Time (fulfillment_latency)"
        },
        "product_tax_code": {
            "definition": "amazon.in's standard code to identify the tax properties of a product.",
            "accepted_value": "Enter the product tax code supplied to you by amazon.in.",
            "example": "A_GEN_NOTAX",
            "label": "Product Tax Code (product_tax_code)",
            "amazon_recommendation": {
                "A_GEN_EXEMPT": "A_GEN_EXEMPT",
                "A_GEN_JEWELLERY": "A_GEN_JEWELLERY",
                "A_GEN_SUPERREDUCED": "A_GEN_SUPERREDUCED",
                "A_GEN_REDUCED": "A_GEN_REDUCED",
                "A_GEN_STANDARD": "A_GEN_STANDARD",
                "A_GEN_PEAK": "A_GEN_PEAK",
                "A_GEN_PEAK_CESS12": "A_GEN_PEAK_CESS12",
                "A_GEN_PEAK_CESS60": "A_GEN_PEAK_CESS60"
            }
        },
        "sale_price": {
            "definition": "The sale price at which the merchant offers the product for sale, expressed in local currency.  The site will strikethrough the product's original price and indicate that the item is on sale at the sale price.",
            "accepted_value": "The price at which you offer the product for sale.",
            "example": "219.99",
            "label": "Sale Price (sale_price)"
        },
        "sale_from_date": {
            "definition": "The date that the sale price will begin to override the product's item_price.",
            "accepted_value": "A date in this format: yyyy-mm-dd.",
            "example": "5/14/2014",
            "label": "Sale Start Date (sale_from_date)"
        },
        "sale_end_date": {
            "definition": "The last date that the sale price will override the item's normal price; the product's standard_price will be displayed afterwards.",
            "accepted_value": "A date in this format: yyyy-mm-dd.",
            "example": "12/2/2004",
            "label": "Sale End Date (sale_end_date)"
        },
        "product_site_launch_date": {
            "definition": "Specify the date the product will be searchable. A product with a future launch date will not be discoverable on the website until the specified launch date.",
            "accepted_value": "This is the date when customers will start viewing this product on Amazon.",
            "example": "MM/DD/YYYY",
            "label": "Launch Date (product_site_launch_date)"
        },
        "merchant_release_date": {
            "definition": "The first date on which you can deliver a pre-orderable product (one that has never been available prior to this date) to a customer",
            "accepted_value": "This field is used to setup pre-order and is the date from when the pre-ordered product will be available to be shipped to customers.",
            "example": "MM/DD/YYYY",
            "label": "Release Date (merchant_release_date)"
        },
        "restock_date": {
            "definition": "This is the date that a merchant will be able to ship any back-ordered items to a customer.\n",
            "accepted_value": "This field is used to setup back-order and is the date when the back-ordered products will be available for shipping to customers.",
            "example": "MM/DD/YYYY",
            "label": "Restock Date (restock_date)"
        },
        "item_package_quantity": {
            "definition": "Specify the package's total number of items. You must provide a value for Item Package Quantity if the product is a \"Child\" product of a \"Parent\" product with a Variation Theme of or including ITEM_PACKAGE_QUANTITY.\n",
            "accepted_value": "A positive integer.\n",
            "example": 3,
            "label": "Item Package Quantity (item_package_quantity)"
        },
        "max_order_quantity": {
            "definition": "Use to indicate the largest quantity that an individual may purchase in one order of the given product.",
            "accepted_value": "A positive whole number.",
            "example": 75,
            "label": "Max Order Quantity (max_order_quantity)"
        },
        "number_of_items": {
            "definition": "The number of items that are included in the product",
            "accepted_value": "A whole number",
            "example": 1,
            "label": "Number of Items (number_of_items)"
        },
        "offering_can_be_gift_messaged": {
            "definition": "If you can print a gift message with the item indicate that here.  If left blank, defaults to 'false'.",
            "accepted_value": "If you can print a gift message with the item indicate that here.  If left blank, defaults to 'false'.",
            "example": "TRUE",
            "label": "Can Be Gift Messaged (offering_can_be_gift_messaged)",
            "amazon_recommendation": {
                "Yes": "Yes",
                "No": "No"
            }
        },
        "offering_can_be_giftwrapped": {
            "definition": "If you can gift wrap an item indicate that here.  If left blank, defaults to 'FALSE'.",
            "accepted_value": "Select: TRUE or FALSE.",
            "example": "FALSE",
            "label": "Is Gift Wrap Available? (offering_can_be_giftwrapped)",
            "amazon_recommendation": {
                "Yes": "Yes",
                "No": "No"
            }
        },
        "is_discontinued_by_manufacturer": {
            "definition": "Select \"True\" if the product is discontinued by the manufacturer or \"False\" if the product is not discontinued by the manufacturer. The default is \"False\".\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "False\n",
            "label": "Discontinued by Manufacturer? (is_discontinued_by_manufacturer)",
            "amazon_recommendation": {
                "Yes": "Yes",
                "No": "No"
            }
        },
        "offering_end_date": {
            "definition": "Specify the date when this item can be discontinued on the site.",
            "accepted_value": "This is the date from when customer cannot place an order on your product, irrespective of the stock in hand. But the product still be active, if other Selling Partners are selling this product.",
            "example": "MM/DD/YYYY",
            "label": "Offer End Date (offering_end_date)"
        },
        "merchant_shipping_group_name": {
            "definition": "Sellers can create a series of distribution templates based on business needs and usage. These templates can contain different distribution areas, expected delivery time, and shipping. When you add product information, sellers need to choose the template to be assosciated with the product to control the product availability as well as ship speed and fee to different regions. Customers will be provided the relevant delivery and shipping options based on this information.",
            "accepted_value": "The ship configuration group for an offer. The ship configuration group is created and managed by the seller through the ship setting UI.",
            "example": " \"Free Shipping Template\", \"Default Template\"",
            "label": "Shipping-Template (merchant_shipping_group_name)"
        },
        "offering_start_date": {
            "definition": "This is the first date on which a merchant can deliver a pre-orderable product (one that has never been available prior to this date) to a customer.",
            "accepted_value": "Once the product is launched on Amazon, customers can start placing orders on your product from this date.",
            "example": "MM/DD/YYYY",
            "label": "Offer Start Date (offering_start_date)"
        },
        "map_price": {
            "definition": "For MAP (Minimum Advertised Price) based pricing.  If you have never heard this term before, this function probably does not apply to you.",
            "accepted_value": "A number with up to 18 digits allowed to the left of the decimal point and 2 digits to the right of the decimal point. Please do not use commas or currency symbols.",
            "example": "259.99",
            "label": "Minimum Advertised Price"
        }
    },
    "Optional": {
        "model_name": {
            "definition": "Provide the model name as described by the manufacturer.",
            "accepted_value": "An alphanumeric string; 1 character minimum in length and 50 characters maximum in length.",
            "example": "Samba",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "suitcase": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Model Name (model_name)"
        },
        "care_instructions1": {
            "definition": "This attribute provides information related to how best to care for the product",
            "accepted_value": "Provide instructions related to how to care for the item",
            "example": "Hand wash, Machine wash",
            "productTypeSpecific": {
                "suitcase": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Care Instructions (care_instructions1)"
        },
        "care_instructions2": {
            "definition": "This attribute provides information related to how best to care for the product",
            "accepted_value": "Provide instructions related to how to care for the item",
            "example": "Hand wash, Machine wash",
            "productTypeSpecific": {
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Care Instructions (care_instructions2)"
        },
        "slot_count": {
            "definition": "This attribute indicates the number of slots that the item has",
            "accepted_value": "This attribute indicates the number of slots that the item has",
            "example": 3,
            "productTypeSpecific": {
                "wallet": {
                    "condition": "required"
                }
            },
            "label": "Slot Count (slot_count)"
        },
        "bullet_point2": {
            "definition": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "accepted_value": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "example": "Expandable\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Bullet Point (bullet_point2)"
        },
        "bullet_point3": {
            "definition": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "accepted_value": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "example": "Expandable\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Bullet Point (bullet_point3)"
        },
        "bullet_point4": {
            "definition": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "accepted_value": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "example": "Expandable\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Bullet Point (bullet_point4)"
        },
        "bullet_point5": {
            "definition": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "accepted_value": "Text - maximum 100 characters. HTML tags and special characters not on a standard keyboard (eg. ®, ©, ™ or other Type 1 High ASCII characters) are not supported. Do not use email IDs, phone numbers, external URLs or promotional messages such as 'sale', 'price', 'offer' etc. Do not include any formatting - Amazon will add the bullet points",
            "example": "Expandable\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Bullet Point (bullet_point5)"
        },
        "material_type2": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type2)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type3": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type3)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type4": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type4)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type5": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type5)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type6": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type6)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type7": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type7)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type8": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type8)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type9": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type9)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "material_type10": {
            "definition": "Select the value(s) that best describe(s) the product's outer material type(s) in order of prominence. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Specify the primary materials used for manufacturing the item",
            "example": "Canvas\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "suitcase": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "luggage": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material Type (material_type10)",
            "amazon_recommendation": {
                "ABS": "ABS",
                "Canvas": "Canvas",
                "Curve": "Curve",
                "Fabric": "Fabric",
                "Felt": "Felt",
                "Leather": "Leather",
                "Metal": "Metal",
                "Nylon": "Nylon",
                "Polycarbonate": "Polycarbonate",
                "Polyester": "Polyester",
                "Polypropylene": "Polypropylene",
                "Synthetic": "Synthetic"
            }
        },
        "shell_type": {
            "definition": "Select the value that best describes the product's casing. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Hard\n",
            "productTypeSpecific": {
                "suitcase": {
                    "condition": "required"
                }
            },
            "label": "Casing (shell_type)"
        },
        "water_resistance_level": {
            "definition": "Select the value that best describes the product's water resistance. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Waterproof\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "suitcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Water Resistance (water_resistance_level)"
        },
        "lifecycle_supply_type": {
            "definition": "",
            "accepted_value": "Specify the intended supply method for the item",
            "example": "Fashion",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Product Lifecycle Supply Type (lifecycle_supply_type)"
        },
        "item_booking_date": {
            "definition": "",
            "accepted_value": "Provide the date a vendor must receive a PO in order to deliver goods to Amazon by a specified date using YYYY-MM-DD format",
            "example": "2020-12-01",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Item Booking Date (item_booking_date)"
        },
        "pocket_description": {
            "definition": "Describes if the wallet has a coin pocket.",
            "accepted_value": "Describes if the wallet has a coin pocket.",
            "example": "Coin pocket",
            "productTypeSpecific": {
                "wallet": {
                    "condition": "required"
                }
            },
            "label": "Pocket Description (pocket_description)"
        },
        "item_dimensions_unit_of_measure": {
            "definition": "The unit of measure used to describe the dimensions (width, length, height) of the product.",
            "accepted_value": "Specify the unit of measure used to describe the dimensions (width, length, height) of the product, expressed in centimeters, meters, inches, or feet.",
            "example": "CM",
            "productTypeSpecific": {
                "suitcase": {
                    "condition": "required"
                }
            },
            "label": "Item Dimensions Unit of Measure (item_dimensions_unit_of_measure)"
        },
        "item_length_unit_of_measure": {
            "definition": "Select a unit of measure for Item Length. You must provide an Item Length Unit of Measure if you provided a value for Item Length. This information will surface in the Product Specifications widget on the detail page and support product discoverability within the store.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Centimeters\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Item Length Unit of Measure (item_length_unit_of_measure)"
        },
        "item_width_unit_of_measure": {
            "definition": "Select a unit of measure for Item Width. You must provide an Item Width Unit of Measure if you provided a value for Item Width. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Centimeters\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Item Width Unit of Measure (item_width_unit_of_measure)"
        },
        "item_height_unit_of_measure": {
            "definition": "Select a unit of measure for Item Height. You must provide an Item Height Unit of Measure if you provided a value for Item Height. This information will surface in the Product Specifications widget on the detail page.\n",
            "accepted_value": "Select from the list of valid values.\n",
            "example": "Centimeters\n",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Item Height Unit of Measure (item_height_unit_of_measure)"
        },
        "supplier_declared_material_regulation1": {
            "definition": "",
            "accepted_value": "Does your product contain any of the following materials/fabrics subject to regulation: Bamboo, Wool, and/or Fur? Please select all that apply. If none apply, select not applicable.",
            "example": "Bamboo, Wool, Fur, Not Applicable",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "required"
                },
                "wallet": {
                    "condition": "required"
                },
                "cosmeticcase": {
                    "condition": "required"
                },
                "waistpack": {
                    "condition": "required"
                }
            },
            "label": "Material/Fabric Regulations (supplier_declared_material_regulation1)"
        },
        "supplier_declared_material_regulation2": {
            "definition": "",
            "accepted_value": "Does your product contain any of the following materials/fabrics subject to regulation: Bamboo, Wool, and/or Fur? Please select all that apply. If none apply, select not applicable.",
            "example": "Bamboo, Wool, Fur, Not Applicable",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material/Fabric Regulations (supplier_declared_material_regulation2)"
        },
        "supplier_declared_material_regulation3": {
            "definition": "",
            "accepted_value": "Does your product contain any of the following materials/fabrics subject to regulation: Bamboo, Wool, and/or Fur? Please select all that apply. If none apply, select not applicable.",
            "example": "Bamboo, Wool, Fur, Not Applicable",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Material/Fabric Regulations (supplier_declared_material_regulation3)"
        },
        "age_range_description": {
            "definition": "",
            "accepted_value": "Specify an appropriate age range description for the item",
            "example": "Baby",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Age Range Description (age_range_description)"
        },
        "target_gender": {
            "definition": "",
            "accepted_value": "Provide the target gender for the product",
            "example": "Female",
            "productTypeSpecific": {
                "duffelbag": {
                    "condition": "optional"
                },
                "wallet": {
                    "condition": "optional"
                },
                "cosmeticcase": {
                    "condition": "optional"
                },
                "waistpack": {
                    "condition": "optional"
                }
            },
            "label": "Target Gender (target_gender)"
        }
    }
}