import { Tooltip } from "@shopify/polaris";
import { TrimTitleI } from "../../type";

export function trimTitle(props: TrimTitleI) {
    const { title, length, substringLength, tooltip } = props;
    if (typeof title === 'string') {
        let titleLength = length ?? 30;
        let substringLen = substringLength ?? 36;
        if (title?.length > titleLength) {
            if (tooltip?.hasTooltip) {
                return <Tooltip content={tooltip.tooltipContent} zIndexOverride={9999999}>
                    <p> {title.substring(0, substringLen) + '...'}</p>
                </Tooltip>
            }
            return title.substring(0, substringLen) + '...';
        }
    }
    return title
}