import React from "react";
import { ModalHandlerI } from "../../type";
import { ContextualSaveBar } from "@shopify/polaris";
import { checkForMutatedStates } from "../../helper";

interface ContextualBarProps {
    globalState: any
    modalHandler: (props: ModalHandlerI) => void
}

export const ContextualBar = React.memo((props: ContextualBarProps) => {

    const { globalState, modalHandler } = props;
    const showSaveBar: boolean = checkForMutatedStates(globalState?.commencedState, globalState?.rows)
    return (
        <>
            {
                showSaveBar && <ContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{
                        content: 'Save',
                        onAction: () => console.log('add form submit logic'),
                        loading: false,
                        disabled: false,
                    }}
                    discardAction={{
                        content: 'Discard',
                        onAction: () => {
                            modalHandler({
                                title: 'Discard Action',
                                data: 'All your changes will be lost and the action cannot be reverted.',
                                isOpen: true
                            })
                        },
                    }}
                />
            }
        </>
    )

})