import React from 'react'
import { InlineStack, Pagination as PolarisPagination, Text } from '@shopify/polaris'
import { _ACTIONS } from '../../context/actions';
import { getParams } from '../../helper';
import { GlobalStateI } from '../../type';

interface PaginationProps {
    state: GlobalStateI
}

function Pagination(props: Readonly<PaginationProps>) {

    const isVariantBulkEdit = getParams();
    const { dispatch, globalState } = props.state ?? {};
    const hasNext = globalState?.pagination?.page < globalState?.pagination?.totalPages;

    const label: React.ReactNode = <InlineStack gap={'0'} align='center'>
        <Text as='p'>{globalState?.pagination?.page}/</Text>
        <Text as='p' tone='subdued'>2</Text>
    </InlineStack>

    const onNext = () => {
        dispatch({
            type: _ACTIONS.PAGINATION_CHANGED,
            payload: {
                pagination: {
                    page: 2,
                    totalPages: 2
                }
            }
        })
    }

    const onPrevious = () => {
        dispatch({
            type: _ACTIONS.PAGINATION_CHANGED,
            payload: {
                pagination: {
                    page: 1,
                    totalPages: 2
                }
            }
        })
    }

    return (
        <PolarisPagination
            hasNext={!isVariantBulkEdit && hasNext}
            hasPrevious={!isVariantBulkEdit && !hasNext}
            label={label}
            onNext={onNext}
            onPrevious={onPrevious}
        />
    )
}

export default Pagination
