import React from 'react'
import BulkEdit from './BulkEdit/BulkEdit'
import { attributesTest, categories } from './constants/categories'
import { rowData } from './constants/products'

function BulkEditWrapper() {
    return (
        <div>
            <BulkEdit
                columns={attributesTest}
                rows={rowData}
                categories={categories}
            />
        </div>
    )
}

export default BulkEditWrapper
