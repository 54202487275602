import { CategoriesI } from "../../type";

export function categoryBuilder(categories: CategoriesI[]) {
    return categories.map((item, index) => {
        const label = `${item.primary_category} > ${item.sub_category}`;
        const id = index.toString();
        const description = item.displayPath.join(' > ');
        const value = index.toString();

        return {
            label,
            id,
            description,
            value
        };
    });
}