import React from 'react'
import BulkEdit from '../BulkEdit/BulkEdit'
import { useLocation } from 'react-router-dom'

function VariantBulkEdit(_props: any) {
    const { state } = useLocation()
    return (
        <div className='variant-grid__wrapper'>
            <BulkEdit columns={state.columns} rows={state.rows} categories={state?.categories ?? []} />
        </div>
    )
}

export default VariantBulkEdit
