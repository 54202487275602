import { Attribute, AttributesObject, ResultItem, SelectedProductsCountI } from "../../type/bulkedit.type";
import { _SHOPIFYATTRIBTUES } from "../constants";

export function formatAmazonRecommendations(amazon_recommendations: any) {
    let formattedAmazonRecommendations: any = [];
    if (Object.keys(amazon_recommendations)?.length > 0) {
        Object.keys(amazon_recommendations)?.forEach(elem => {
            formattedAmazonRecommendations.push({
                label: amazon_recommendations[elem],
                value: amazon_recommendations[elem]
            })
        })
    }
    return formattedAmazonRecommendations
}

export function checkForMutatedStates(initialState: any = {}, modifiedState: any = {}) {
    let isModified: boolean = false;

    let iterator = [...initialState?.rows ?? []];

    iterator?.forEach((state, index) => {
        for (const rowKey in state) {
            if (!isModified && state[rowKey]?.displayValue !== modifiedState?.[index]?.[rowKey]?.displayValue) {
                isModified = true;
                return
            }
        }
    })

    return isModified
}

export function trimTitle(title: string) {
    if (typeof title === 'string') {
        if (title?.length > 30)
            return title.substring(0, 36) + '...'
    }
    return title
}

export function selectedProductsCount(props: SelectedProductsCountI) {
    let { selectedRowKeys } = props;
    let rectifiedSelectedRows = selectedRowKeys?.filter(elem => elem);
    return rectifiedSelectedRows?.length
}

/**
 * 
 * @param attributes 
 * @param withHeaderLables - adds the outermost key as 'labels' and 'options' as their values 
 * @returns [
    {
      label: '"Shopify Core Attributes"',
      options: [
        { label: 'title', value: 'code' },
        { label: 'title', value: 'code' },
      ],
    },
    ...
    ]
 */

export function formatShopifyAttributes(attributes: any, withHeaderLables?: boolean) {
    let result: any = [];

    if (withHeaderLables)
        result = attributesWithHeaderLables(attributes);
    else
        for (const category in attributes) {
            for (const attribute of attributes[category]) {
                const { code, title } = attribute;

                result.push({
                    label: title,
                    value: code
                });
            }
        }

    return result
}

export const attributesWithHeaderLables = (input: AttributesObject): ResultItem[] => {
    const result: ResultItem[] = [];

    const processAttributes = (attributes: Attribute[], parentLabel: string | null = null) => {
        const options: { label: string; value: string }[] = [];

        attributes.forEach((attribute) => {
            options.push({
                label: attribute.title ?? attribute.code,
                value: attribute.code,
            });

            if (attribute.children && Array.isArray(attribute.children) && attribute.children.length > 0) {
                processAttributes(attribute.children, attribute.code);
            }
        });

        if (parentLabel) {
            result.push({
                label: parentLabel,
                options: options,
            });
        }
    };

    for (const key in input) {
        if (input.hasOwnProperty(key) && Array.isArray(input[key]) && input[key].length > 0) {
            processAttributes(input[key], key);
        }
    }

    return result;
}

export function extractCurrentSelection(attribute: any, respectiveColdata: any) {
    if (attribute?.shopify_select === 'Search')
        return {
            type: attribute?.shopify_select,
            value: attribute?.shopify_attribute,
            options: formatShopifyAttributes(_SHOPIFYATTRIBTUES, true)
        }
    else if (attribute?.shopify_select === "recommendation")
        return {
            type: attribute?.shopify_select,
            value: attribute?.recommendation,
            options: respectiveColdata?.amazon_recommendation ?? []
        }
    else if (attribute?.shopify_select === "custom")
        return {
            type: attribute?.shopify_select,
            value: attribute?.custom_text
        }
}

export function getParams() {
    return window.location.pathname === '/variant-edit'
}