export const _STATICCOLUMNS: any[] = [
    {
        title: 'Product Details',
        dataIndex: 'ProductDetails',
        render: (t: any) => {
            if (t === undefined) return <div className='for-row-span' >Edit This Row to Update Selcted Products</div>
            else return t
        },
        width: '320px',
        visible: true,
        fixed: true,
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        render: (t: any) => {
            if (t === undefined) return <div className='for-display-none' >Edit This Row to Update Selcted Products</div>
            else return t
        },
        width: '225px',
        visible: true,
        fixed: true,
    }
];


export const _SHOPIFYATTRIBTUES = {
    "Shopify Core Attributes": [
        {
            "code": "handle",
            "title": "Handle",
            "required": 0
        },
        {
            "code": "product_type",
            "title": "Product Type",
            "required": 0
        },
        {
            "code": "title",
            "title": "title",
            "required": 0
        },
        {
            "code": "brand",
            "title": "brand",
            "required": 0
        },
        {
            "code": "description",
            "title": "description",
            "required": 0
        },
        {
            "code": "tags",
            "title": "tags",
            "required": 0
        },
        {
            "code": "sku",
            "title": "sku",
            "required": 0
        },
        {
            "code": "price",
            "title": "price",
            "required": 0
        },
        {
            "code": "quantity",
            "title": "quantity",
            "required": 0
        },
        {
            "code": "weight",
            "title": "weight",
            "required": 0
        },
        {
            "code": "weight_unit",
            "title": "weight_unit",
            "required": 0
        },
        {
            "code": "grams",
            "title": "grams",
            "required": 0
        },
        {
            "code": "barcode",
            "title": "barcode",
            "required": 0
        }
    ],
    "Variant Attributes": [
        {
            "code": "variant_title",
            "title": "Variant title",
            "required": 0
        },
        {
            "code": "COLOR",
            "title": "COLOR",
            "required": 0
        },
        {
            "code": "SIZE",
            "title": "SIZE",
            "required": 0
        }
    ],
    "Cedcommerce Attributes": [
        {
            "code": "type",
            "title": "type",
            "required": 0
        }
    ],
    "Meta attributes": []
}