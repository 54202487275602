import { InlineStack, BlockStack, Text, Button, Page, Icon, Modal, Card } from '@shopify/polaris';
import React, { useEffect, useReducer, useState } from 'react'
import Category from './components/Category/Category';
import './styles/bulkedit.css';
import { reducer } from './context/reducer';
import { _ACTIONS } from './context/actions';
import { checkForMutatedStates, isRowElementDisabled, selectedProductsCount, formatShopifyAttributes, getParams } from './helper';
import { BulkeditI, ModalHandlerI, ResetDataI } from './type/bulkedit.type';
import { useNavigate } from 'react-router-dom';
import SkeltonBulkEditGrid from './skeltonBulkEdit/SkeltonBulkEditGrid';
import { _SHOPIFYATTRIBTUES } from './helper/constants';
import { OnCellClickedI } from './type/rows.type';
import Pagination from './components/Pagination/Pagination';
import { rowData2 } from '../constants/products';
import { Columns3Major } from '@shopify/polaris-icons';
import { ManageColumns } from './components/ManageColumns';
import { BulkEditGrid } from './components/BulkEditTable';
import { columnBuilder } from './helper/controller/columnController';
import { closePopoverIfRowInSelection, rowBuilder } from './helper/controller/rowController';
import { ContextualBar } from './components/ContextualSaveBar';
import { categoryBuilder } from './helper/controller/categoryController';
import { CategoriesI } from './type';

const BulkEdit = React.memo((props: Readonly<BulkeditI>) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
    const [globalState, dispatch] = useReducer(reducer, {
        pagination: {
            page: 1,
            totalPages: 2
        }
    });
    const [selectAllCols, setSelectAllCols] = useState<any>({});
    const [isCollapsible, setIsCollapsible] = useState<any>({});
    const [loading, setLoading] = useState(true);
    let tempCommencedState: any = {};
    const naviate = useNavigate();
    const isVariantBulkEdit = getParams();
    const [modal, setModal] = useState({
        title: '',
        isOpen: false,
        data: [],
        type: '',
        size: 'medium',
        primaryAction: [],
    })

    const [selectedCell, setSelectedCell] = useState<any>({
        rowIndex: 0,
        columnIndex: 0,
        colName: ''
    })

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        closePopoverIfRowInSelection({
            rows: globalState?.rows,
            selectedKeys: newSelectedRowKeys
        });
        setSelectedCell({ rowIndex: null, columnIndex: null, colName: "" });

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => {
            if (record.index === undefined) {
                return {
                    style: {
                        display: 'none',
                    },
                    disabled: record?.index === undefined
                };
            }
            return {
                disabled: record?.index === undefined//', // Disable checkbox for the first row
            }
        },
    };

    function handleClickOutside(event: MouseEvent) {
        if (!selectedCell.rowIndex && selectedCell.colName === "") return;
        const popoverContentContainer = document.querySelector<HTMLElement>(".Polaris-Popover__ContentContainer");
        const customMoreActionEles = document.querySelectorAll<HTMLElement>(".selected_cell");
        const isInsidePopover = popoverContentContainer && event.composedPath().includes(popoverContentContainer);
        const isInsideCustomMoreAction = Array.from(customMoreActionEles).some(ele =>
            event.composedPath().includes(ele)
        );
        if (!isInsidePopover && !isInsideCustomMoreAction) {
            resetCell(selectedCell?.columnIndex, selectedCell?.colName)
        }
    };

    function resetCell(index: any, key: any) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        tempRows[index][key]['clicked'] = '';
        setSelectedCell({ rowIndex: null, columnIndex: null, colName: "" });
        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows
            }
        })
    }

    useEffect(() => {
        window.addEventListener("click", handleClickOutside)
        return () => {
            window.removeEventListener("click", handleClickOutside)
        }
        // eslint-disable-next-line 
    }, [selectedCell])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
            prepareColumns(props.columns);
            prepareRows(
                globalState?.pagination && globalState?.pagination?.page === 2 ? rowData2 : props.rows
            );
            prepareShopifyAttributes();
            prepareCategories(props.categories)
        }, 500);
        // eslint-disable-next-line 
    }, [globalState?.pagination])

    function prepareCategories(categories: CategoriesI[]) {
        const tempCategories = categoryBuilder(categories);
        dispatch({
            type: _ACTIONS.CATEGORIES,
            payload: {
                categories: [...tempCategories]
            }
        })
    }

    function prepareShopifyAttributes() {
        let shhopifyAttributes: any = formatShopifyAttributes(_SHOPIFYATTRIBTUES, true);
        dispatch({
            type: _ACTIONS.SHOPIFY_ATTRIBUTES,
            payload: {
                shopifyAttributes: shhopifyAttributes
            }
        })
    }

    useEffect(() => {
        setSelectedRowKeys([])
    }, [globalState?.pagination?.page])

    function prepareRows(rows: any) {
        let tempRows: any = rowBuilder({
            columns: props?.columns,
            rows: rows,
            isVariantBulkEdit
        });

        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows,
            }
        });
        commencedState('rows', JSON.parse(JSON.stringify(tempRows)))
    }

    function prepareColumns(columns: any) {
        const { collapsibles, manageColumns, result, selectAllCols } = columnBuilder({
            columns,
            isVariantBulkEdit,
            rows: props.rows,
        });
        setSelectAllCols(selectAllCols);
        setIsCollapsible(collapsibles);
        dispatch({
            type: _ACTIONS.COLUMNS,
            payload: {
                [_ACTIONS.MANAGE_COLS]: manageColumns,
                [_ACTIONS.COLUMNS]: result
            }
        });
        commencedState('columns', JSON.parse(JSON.stringify(result)));
    }

    function commencedState(key: string, value: any) {
        tempCommencedState[key] = value;
        dispatch({
            type: _ACTIONS.SET_COMMENCED_STATE,
            payload: {
                commencedState: {
                    ...globalState?.commencedState,
                    ...tempCommencedState
                }
            }
        })
    }

    function selectAllManagedCols(key: string, value: any, sub_key: any) {
        let temp: any = { ...selectAllCols };
        temp[key]['selectAll'] = !value;

        let tempManageCols: any = { ...(globalState?.manage_columns ?? {}) };
        let tempCols: any = [...(globalState?.columns ?? [])];
        Object.keys(tempManageCols[key] ?? [])?.forEach(elem => {
            tempManageCols[key][elem]['visible'] = !value;
        })

        tempCols?.forEach((elem: any) => {
            if (elem?.type === key)
                elem['visible'] = !value;
        })

        dispatch({
            type: _ACTIONS.COLUMNS,
            payload: {
                [_ACTIONS.MANAGE_COLS]: tempManageCols,
                [_ACTIONS.COLUMNS]: tempCols,
            }
        })
        setSelectAllCols(temp)
    }

    function handleCollapsible(key: string): any {
        let tempCollapsibles: any = { ...isCollapsible };
        tempCollapsibles[key]['isOpen'] = !tempCollapsibles?.[key]?.isOpen;
        setIsCollapsible(tempCollapsibles)
    }

    function redirectToVariantEdit(variantData: any) {
        prepareRows(variantData)
        naviate('/variant-edit', {
            state: {
                rows: variantData,
                columns: props.columns
            }
        });
    }

    function resetData(_props: ResetDataI) {
        let obj = {
            single: resetSingleCell,
            bulk: resetBulkEdit,
            defaultvalues: resetToDefaultValues
        }
        const callObj = obj[_props.type];
        callObj((_props))
    }

    function resetToDefaultValues() {
        let tempRows: any = [...(JSON.parse(JSON.stringify(globalState?.commencedState['rows'])) ?? [])];
        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows
            }
        });
        setSelectedRowKeys([]);
    }

    function resetSingleCell(_props: ResetDataI) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        let { index, key } = _props
        tempRows[index][key] = { ...JSON.parse(JSON.stringify(globalState?.commencedState['rows'][index][key])) }
        tempRows[index][key]['isModified'] = false;
        tempRows[index][key]['clicked'] = '';
        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows
            }
        })
    }

    function resetBulkEdit(_props: ResetDataI) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        let { key } = _props;
        tempRows?.forEach((elem: any, index: number) => {
            elem[key] = { ...JSON.parse(JSON.stringify(globalState?.commencedState?.rows[index][key])) }
            elem[key]['displayValue'] = globalState?.commencedState?.rows[index][key]['displayValue'];
            elem[key]['isModified'] = false;
            tempRows[index][key]['clicked'] = '';
        })
        if (!checkForMutatedStates(globalState?.commencedState, globalState?.rows))
            setSelectedRowKeys([])
        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows
            }
        })
    }

    const handleCellClick = (props: OnCellClickedI) => {
        let { colName, colIndexInRow, parentRowIndex, rows, colIndex } = props;

        //colIndex - Index of the column within the matrix;
        setSelectedCell({
            columnIndex: parentRowIndex,
            rowIndex: colIndexInRow,
            colName: colName
        })
        if (!isRowElementDisabled({
            rowSelection: { ...rowSelection },
            key: rows['key']['displayValue']
        })) {
            toggleEditAble(colName, parentRowIndex, colIndexInRow);
        }
    };

    function toggleEditAble(key: any, parentIndex: number, childIndex: number, resetAll?: boolean) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        tempRows?.forEach((elem: any, parentInd: number) => {
            Object.keys(elem ?? {}).forEach((rowChild: any, childInd: number) => {
                if (elem[rowChild]?.['clicked'] !== undefined) {
                    if ((parentInd === parentIndex && childInd === childIndex) && !resetAll) {
                        updateToggleState({
                            tempRows, parentIndex, key
                        })
                    }
                    else
                        elem[rowChild]['clicked'] = ''
                }
            })
        })

        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows,
            }
        });
    }

    function updateToggleState(props: any) {
        const { tempRows, parentIndex, key } = props;
        if (tempRows[parentIndex][key]['clicked'] === '') {
            tempRows[parentIndex][key]['clicked'] = 'selected';
        }
        else if (tempRows[parentIndex][key]['clicked'] === 'selected') {
            tempRows[parentIndex][key]['clicked'] = 'editing';
        }
    }

    function modalHandler(props: ModalHandlerI) {
        let tempModal: any = { ...modal };
        tempModal['title'] = props?.title;
        tempModal['isOpen'] = props?.isOpen;
        tempModal['data'] = props?.data;
        tempModal['type'] = props?.type;
        tempModal['size'] = props?.size;
        setModal(tempModal);
    }

    return (
        <>
            <Modal
                open={modal.isOpen}
                title={modal.title}
                onClose={() => {
                    setModal({
                        ...modal,
                        isOpen: false
                    })
                }}
                primaryAction={{
                    content: 'Discard',
                    onAction: () => {
                        resetToDefaultValues();
                        modalHandler({ isOpen: false })
                    }
                }}
            >
                <Modal.Section>
                    {modal.data ?? []}
                </Modal.Section>
            </Modal>
            <Page
                fullWidth
                backAction={{ content: 'Products', url: '/bulkedit' }}
                title="Bulk Edit"
                subtitle="This feature allows you to edit product details for multiple products in one go.  It will save your valuable time by enabling you to update title, description, prices, inventory levels, and more across a large inventory. The value you choose in below attributes for selected products will get synchronize on Amazon marketplace"
            >
                <ContextualBar
                    globalState={globalState}
                    modalHandler={modalHandler}
                />
                <Card padding={'100'}>
                    <div className='card--padding'>
                        <BlockStack gap={"200"}>
                            {loading ? <SkeltonBulkEditGrid /> :
                                <>
                                    <div className='card-header--padding'>
                                        <InlineStack align='space-between' gap={"200"} blockAlign='center'>
                                            <Text as="h4" fontWeight='bold' variant='headingSm'>
                                                Selected {selectedProductsCount({
                                                    selectedRowKeys: selectedRowKeys
                                                })} items
                                            </Text>
                                            <InlineStack gap={"300"}>
                                                {!getParams() && <Category categories={globalState?.categories} />}
                                                <div className='vertical--lines'></div>
                                                <Button onClick={() => { setIsSideSheetOpen(true) }} icon={<Icon
                                                    source={Columns3Major}
                                                />} />
                                            </InlineStack>
                                        </InlineStack>
                                    </div>
                                    <div className='bulkEdit-grid--wrapper'>
                                        <BulkEditGrid
                                            resetData={resetData}
                                            rowSelection={rowSelection}
                                            state={{ globalState, dispatch }}
                                            toggleEditAble={toggleEditAble}
                                            redirectToVariantEdit={redirectToVariantEdit}
                                            handleCellClick={handleCellClick}
                                            selectedCell={{
                                                selectedCell,
                                                setSelectedCell
                                            }}
                                        />
                                    </div>
                                    <InlineStack align='center' blockAlign='center'>
                                        <Pagination
                                            state={{
                                                globalState,
                                                dispatch
                                            }}
                                        />
                                    </InlineStack>
                                </>
                            }
                        </BlockStack>
                    </div>
                </Card>
            </Page >
            <ManageColumns
                isOpen={isSideSheetOpen}
                onClose={setIsSideSheetOpen}
                collapsible={{
                    isCollapsible: isCollapsible,
                    handleCollapsible: handleCollapsible
                }}
                selectedColumns={{
                    selectAllCols: selectAllCols,
                    selectAllManagedCols: selectAllManagedCols
                }}
                state={{
                    globalState,
                    dispatch
                }}
            />
        </>
    );
});

export default BulkEdit
