import React, { useState } from 'react';
import {
    Listbox,
    TextField,
    Icon,
    Popover,
    AutoSelection,
    Scrollable,
    EmptySearchResult,
    Text,
    BlockStack,
    Button,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { FormattedCategoryI } from '../../type';

const actionValue = '__ACTION__';

const segments = [
    {
        "label": "book > bookloader",
        "id": "0",
        "description": "Books > Arts, Film & Photography > Dance",
        "value": "0"
    },
    {
        "label": "kitchen > artandcraftsupply",
        "id": "1",
        "description": "Toys & Games > Arts & Crafts > Clay & Dough",
        "value": "1"
    },
    {
        "label": "book > bookloader",
        "id": "2",
        "description": "Books > Humour",
        "value": "2"
    },
    {
        "label": "shoe_handbags > boot",
        "id": "3",
        "description": "Fashion > Boys > Shoes > Casual Shoes > Boots",
        "value": "3"
    },
    {
        "label": "book > bookloader",
        "id": "4",
        "description": "Books > Arts, Film & Photography > Cinema & Broadcast",
        "value": "4"
    },
    {
        "label": "electronics_pc > computer",
        "id": "5",
        "description": "Sports, Fitness & Outdoors > Airsoft > Battery Chargers",
        "value": "5"
    },
    {
        "label": "musical_instruments > instrumentpartsandaccessories",
        "id": "6",
        "description": "Musical Instruments > String Instruments > Accessories > Cases & Bags",
        "value": "6"
    },
    {
        "label": "video_games_games > software",
        "id": "7",
        "description": "Software > Accounting & Finance > Personal Finance > Money Management & Budgeting",
        "value": "7"
    },
    {
        "label": "home_improvement > door",
        "id": "8",
        "description": "Industrial & Scientific > Commercial Door Products > Commercial Doors > Fire Doors",
        "value": "8"
    }
]

const interval = 25;

interface CategoryProp {
    categories: FormattedCategoryI[]
}

function Category(props: Readonly<CategoryProp>) {
    const [pickerOpen, setPickerOpen] = useState(false);
    const [showFooterAction, setShowFooterAction] = useState(true);
    const [query, setQuery] = useState('');
    const [lazyLoading, setLazyLoading] = useState(false);
    const [willLoadMoreResults, setWillLoadMoreResults] = useState(true);
    const [visibleOptionIndex, setVisibleOptionIndex] = useState(6);
    const [activeOptionId, setActiveOptionId] = useState(segments[0].id);
    const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
    const [filteredSegments, setFilteredSegments] = useState<
        typeof segments[number][]
    >([]);

    //use props.categories for formatted categories

    const handleClickShowAll = () => {
        setShowFooterAction(false);
        setVisibleOptionIndex(interval);
    };

    const handleFilterSegments = (query: string) => {
        const nextFilteredSegments = segments.filter((segment) => {
            return segment.label
                .toLocaleLowerCase()
                .includes(query.toLocaleLowerCase().trim());
        });

        setFilteredSegments(nextFilteredSegments);
    };

    const handleQueryChange = (query: string) => {
        setQuery(query);

        if (query.length >= 2) handleFilterSegments(query);
    };

    const handleQueryClear = () => {
        handleQueryChange('');
    };

    const handleOpenPicker = () => {
        setPickerOpen(!pickerOpen);
    };

    const handleClosePicker = () => {
        setPickerOpen(false);
        handleQueryChange('');
    };

    const handleSegmentSelect = (segmentIndex: string) => {
        if (segmentIndex === actionValue) {
            return handleClickShowAll();
        }

        setSelectedSegmentIndex(Number(segmentIndex));
        handleClosePicker();
    };

    const handleActiveOptionChange = (_: string, domId: string) => {
        setActiveOptionId(domId);
    };

    /* This is just to illustrate lazy loading state vs loading state. This is an example, so we aren't fetching from GraphQL. You'd use `pageInfo.hasNextPage` from your GraphQL query data instead of this fake "willLoadMoreResults" state along with setting `first` your GraphQL query's variables to your app's default max edges limit (e.g., 250). */

    const handleLazyLoadSegments = () => {
        if (willLoadMoreResults && !showFooterAction) {
            setLazyLoading(true);

            const options = query ? filteredSegments : segments;

            setTimeout(() => {
                const remainingOptionCount = options.length - visibleOptionIndex;
                const nextVisibleOptionIndex =
                    remainingOptionCount >= interval
                        ? visibleOptionIndex + interval
                        : visibleOptionIndex + remainingOptionCount;

                setLazyLoading(false);
                setVisibleOptionIndex(nextVisibleOptionIndex);

                if (remainingOptionCount <= interval) {
                    setWillLoadMoreResults(false);
                }
            }, 1000);
        }
    };

    const listboxId = 'SearchableListboxInPopover';



    /* Your app's feature/context specific activator here */
    const activator = (
        <div className=''>
            <Button disclosure onClick={handleOpenPicker}>{segments[selectedSegmentIndex].label}</Button>
        </div>
    );

    const textFieldMarkup = (
        <div style={{ padding: '12px' }}>
            <StopPropagation>
                <TextField
                    focused={showFooterAction}
                    clearButton
                    labelHidden
                    label="Customer segments"
                    placeholder="Search segments"
                    autoComplete="off"
                    value={query}
                    prefix={<Icon source={SearchMinor} />}
                    ariaActiveDescendant={activeOptionId}
                    ariaControls={listboxId}
                    onChange={handleQueryChange}
                    onClearButtonClick={handleQueryClear}
                />
            </StopPropagation>
        </div>
    );

    const segmentOptions = query ? filteredSegments : segments;

    const segmentList =
        segmentOptions.length > 0
            ? segmentOptions
                .slice(0, visibleOptionIndex)
                .map(({ label, id, description, value }) => {
                    const selected = segments[selectedSegmentIndex].id === id;

                    return (
                        <Listbox.Option key={id} value={value} selected={selected}>
                            <Listbox.TextOption selected={selected}>
                                <BlockStack gap={"100"}>
                                    <Text as='p'>{label}</Text>
                                    <Text as='p' tone='subdued'>{description}</Text>
                                </BlockStack>
                            </Listbox.TextOption>
                        </Listbox.Option>
                    );
                })
            : null;

    const showAllMarkup = showFooterAction ? (
        <Listbox.Action value={actionValue}>
            <span style={{ color: 'var(--p-color-text-emphasis)' }}>
                Show all {segments?.length} segments
            </span>
        </Listbox.Action>
    ) : null;

    const lazyLoadingMarkup = lazyLoading ? (
        <Listbox.Loading
            accessibilityLabel={`${query ? 'Filtering' : 'Loading'
                } customer segments`}
        />
    ) : null;

    const noResultsMarkup =
        segmentOptions.length === 0 ? (
            <EmptySearchResult
                title=""
                description={`No segments found matching "${query}"`}
            />
        ) : null;

    const listboxMarkup = (
        <Listbox
            enableKeyboardControl
            autoSelection={AutoSelection.FirstSelected}
            accessibilityLabel="Search for and select a customer segment"
            customListId={listboxId}
            onSelect={handleSegmentSelect}
            onActiveOptionChange={handleActiveOptionChange}
        >
            {segmentList}
            {showAllMarkup}
            {noResultsMarkup}
            {lazyLoadingMarkup}
        </Listbox>
    );

    return (

        <Popover
            active={pickerOpen}
            activator={activator}
            ariaHaspopup="listbox"
            preferredAlignment="right"
            autofocusTarget="first-node"
            onClose={handleClosePicker}
        >
            <Popover.Pane fixed>
                <div
                    style={{
                        alignItems: 'stretch',
                        borderTop: '1px solid #DFE3E8',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'stretch',
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {textFieldMarkup}

                    <Scrollable
                        shadow
                        style={{
                            position: 'relative',
                            width: '310px',
                            height: '292px',
                            padding: 'var(--p-space-200) 0',
                            borderBottomLeftRadius: 'var(--p-border-radius-200)',
                            borderBottomRightRadius: 'var(--p-border-radius-200)',
                        }}
                        onScrolledToBottom={handleLazyLoadSegments}
                    >
                        {listboxMarkup}
                    </Scrollable>
                </div>
            </Popover.Pane>
        </Popover>

    );
}

export default Category;


const StopPropagation = ({ children }: React.PropsWithChildren<any>) => {
    const stopEventPropagation = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
    };

    return (
        <div role='none' onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
            {children}
        </div>
    );
};