import { BlockStack, Button, Divider, InlineStack, Popover, RadioButton, TextField, Text, Modal } from "@shopify/polaris";
import { HTMLElementI, RenderPopoverI } from "../../type"
import React, { useCallback, useState } from "react";
import { _DROPDOWN } from "../../../../assets/iconsSvg";
import { formatAmazonRecommendations } from "../../helper";
import { Select, Timeline } from 'antd'

export const HTMLElement = (props: HTMLElementI) => {
    const { type } = props;
    const typeSelector = {
        popover: <CedPopover {...props} />,
        select: <CedSelect />,
        textfield: <CedTextField />
    }

    return <>
        {typeSelector[type]}
    </>
}

export const CedPopover = React.memo((_props: HTMLElementI) => {
    const { payload, onDataChange, toggleEditAble, tableElementType } = _props;
    const { popover, columnName, indexMatrix, } = payload;
    const { amazonRecommendation, hasAmazonRecommendation, shopifySelect, currentSelection, displayValue, clicked } = popover ?? {};
    const [popoverActive, setPopoverActive] = useState(clicked ?? false);
    const [selectionType, setSelectionType] = useState<any>(getInitialSelection());
    const [setCustomValue, setSetCustomValue] = useState(getInitialCustomTextValue());
    const [openHistory, setOpenHistory] = useState(false);
    const [shopify, setShopify] = useState({
        options: shopifySelect?.options,
        value: shopifySelect?.value
    });
    const [amazon, setAmazon] = useState({
        options: amazonRecommendation?.options ?? [],
        value: amazonRecommendation?.value ?? ''
    });
    const [errors, setErrors] = useState<any>({
        shopify: '',
        amazon: '',
        setCustom: ''
    });

    type ErrorKeyType = 'shopify' | 'amazon' | 'setCustom'

    function getInitialSelection() {
        let { currentSelection } = _props.payload.popover ?? {};
        if (currentSelection?.type === 'Search')
            return 'shopifySelect';
        else if (currentSelection?.type === 'custom' || currentSelection?.type === 'recommendation')
            return 'others'
        return ''
    }

    function getInitialCustomTextValue() {
        if (currentSelection?.type === 'custom') {
            return currentSelection.value;
        }
        else return ''
    }

    const resetPopover = () => {
        setSelectionType(getInitialSelection());
        setShopify({
            options: shopifySelect?.options,
            value: shopifySelect?.value
        });
        if (hasAmazonRecommendation) {
            setAmazon({
                options: amazonRecommendation?.options ?? [],
                value: amazonRecommendation?.value ?? ''
            })
        }
        else {
            setSetCustomValue(getInitialCustomTextValue());
        };
        setErrors({
            shopify: '',
            amazon: '',
            setCustom: ''
        })
    }

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );

    const handleChange = useCallback(
        (_: boolean, newValue: any) => setSelectionType(newValue),
        []
    );

    const updatePopoverContent = () => {
        let targetSelection = {
            shopifySelect: { ..._props?.payload?.popover?.shopifySelect ?? {} },
            amazonRecommendation: { ..._props?.payload?.popover?.amazonRecommendation ?? {} },
            customText: { ..._props?.payload?.popover?.customText },
            currentSelection: { ..._props?.payload?.popover?.currentSelection }
        }
        if (selectionType === 'others') {
            if (hasAmazonRecommendation) {
                targetSelection.currentSelection.type = 'recommendation';
                targetSelection.amazonRecommendation.value = amazon.value;
                targetSelection.currentSelection.value = amazon.value;
                targetSelection.amazonRecommendation.options = amazon.options
                targetSelection.currentSelection.options = amazon.options
            }
            else {
                targetSelection.currentSelection.type = 'custom';
                targetSelection.currentSelection.value = setCustomValue;
                targetSelection.customText.value = setCustomValue;
            }
        } else if (selectionType === 'shopifySelect') {
            targetSelection.currentSelection.type = 'Search';
            targetSelection.shopifySelect.value = shopify.value;
            targetSelection.currentSelection.value = shopify.value;
            targetSelection.shopifySelect.options = shopify.options
            targetSelection.currentSelection.options = shopify.options
        }
        return { ...targetSelection }
    }

    const errorHandler = (key: ErrorKeyType, value: any) => {
        let tempErrors: any = { ...errors };
        tempErrors[key] = value;
        setErrors(tempErrors);
    }

    const validator = () => {
        let hasError: boolean = false;

        if (selectionType === 'shopifySelect') {
            hasError = shopify.value === '';
            errorHandler('shopify', shopify.value === '' ? 'error' : '');
        }
        else if (selectionType === 'others') {
            if (hasAmazonRecommendation) {
                hasError = amazon.value === '';
                errorHandler('amazon', amazon.value === '' ? 'error' : '')
            }
            else {
                hasError = setCustomValue === '';
                errorHandler('setCustom', setCustomValue === '' ? 'error' : '')
            }
        }

        return {
            hasError
        }
    }

    const onSave = () => {
        let { hasError } = validator();
        if (!hasError) {
            if (toggleEditAble)
                toggleEditAble(columnName, 0, 0, true);

            const targetSelection = updatePopoverContent();
            onDataChange(columnName, getSelectedValueType(), indexMatrix?.row, targetSelection);
            togglePopoverActive(); // turn popover off
        }
    };

    const getSelectedValueType = () => {
        if (selectionType === 'others') {
            return hasAmazonRecommendation ? amazon.value : setCustomValue;
        } else if (selectionType === 'shopifySelect') {
            return shopify.value;
        }
        return '';
    };

    const renderShopifySelect = () => (
        <Select
            placeholder={'Enter value'}
            status={errors.shopify}
            onClick={(e) => e.stopPropagation()}
            showSearch
            id="select__shopify-attribute"
            options={shopify.options}
            value={shopify.value || undefined}
            onChange={(event) => {
                setShopify({
                    ...shopify,
                    value: event
                });
                errorHandler('shopify', '');
            }}
            onSearch={() => {
                errorHandler('shopify', '');
            }}
            style={{ width: '200px' }}
        />
    );

    const renderAmazonSelect = () => (
        <Select
            placeholder={'Enter value'}
            status={errors.amazon}
            onClick={(e) => e.stopPropagation()}
            showSearch
            options={formatAmazonRecommendations(amazon?.options ?? {}) ?? []}
            value={amazon.value || undefined}
            onChange={(event) => {
                setAmazon({
                    ...amazon,
                    value: event
                });
                errorHandler('amazon', '');
            }}
            onSearch={() => {
                errorHandler('amazon', '');
            }}
            style={{ width: '200px' }}
        />
    );

    const renderTextField = () => (
        <TextField
            placeholder="Enter value"
            error={errors.setCustom === 'error'}
            autoComplete=""
            label
            onChange={(event) => {
                setSetCustomValue(event);
                errorHandler('setCustom', '');
            }}
            value={setCustomValue}
        />
    );

    const helpTextElement = (type: 'shopifySelect' | 'others'): JSX.Element | null => {
        if (type === 'shopifySelect' && selectionType === 'shopifySelect') {
            return renderShopifySelect();
        }
        else if (type === 'others' && selectionType === 'others') {
            return hasAmazonRecommendation ? renderAmazonSelect() : renderTextField();
        }
        return null;
    };

    const disableOnsave = () => {
        if (selectionType !== getInitialSelection()) {
            return false;
        }
        if (selectionType === 'others') {
            if (hasAmazonRecommendation) {
                return amazon.value === _props?.payload?.popover?.amazonRecommendation?.value;
            } else {
                return setCustomValue === getInitialCustomTextValue();
            }
        } else if (selectionType === 'shopifySelect') {
            return shopify.value === _props?.payload?.popover?.shopifySelect?.value;
        }
        return true;
    }

    return renderPopover({
        onSave, togglePopoverActive, handleChange, helpTextElement, resetPopover, disableOnsave, tableElementType, displayValue, popoverActive, hasAmazonRecommendation, selectionType, history: {
            ..._props.history,
            modalHandler: {
                openHistory, setOpenHistory
            }
        }
    });
});

const renderPopover = (props: RenderPopoverI) => {
    let { onSave,
        togglePopoverActive,
        handleChange,
        helpTextElement,
        disableOnsave,
        resetPopover,
        popoverActive,
        hasAmazonRecommendation,
        tableElementType,
        displayValue,
        selectionType,
        history
    } = props

    const activator = <div className={tableElementType === 'rows' ? "popover__activator--button" : 'attribute-selection__wrapper'}>
        {
            tableElementType === 'columns' ? <Button onClick={togglePopoverActive} disclosure fullWidth textAlign="left">
                Attribute Selection
            </Button> :
                <Button variant="tertiary" icon={_DROPDOWN} onClick={togglePopoverActive} fullWidth textAlign="left">
                    {displayValue || '---'}
                </Button>
        }
    </div>

    let items: any = [];
    if (history?.historyStack) {
        history?.historyStack?.forEach((elem: any, index: number) => {
            items.push({
                color: index === history?.historyStack?.length - 1 ? "green" : 'gray',
                children: `${elem?.type} and ${elem?.value}`
            })
        })
    }

    return (
        <>
            <Modal sectioned onClose={() => {
                history?.modalHandler?.setOpenHistory(false)
            }} open={history?.modalHandler?.openHistory} title='Attribute History'>
                <Timeline items={items} />
            </Modal>
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                ariaHaspopup={false}
                sectioned
                preferredAlignment='left'
                fullWidth
                fullHeight
            >
                <BlockStack gap={'300'}>
                    <BlockStack gap={'0'}>
                        <RadioButton
                            label="Shopify Attributes"
                            // helpText={helpTextElement('shopifySelect')}
                            checked={selectionType === 'shopifySelect'}
                            id="shopifySelect"
                            name="attributes"
                            onChange={handleChange}
                        />
                        <div className="selected__attribute-element">
                            {selectionType === 'shopifySelect' && helpTextElement('shopifySelect')}
                        </div>
                        <RadioButton
                            label={hasAmazonRecommendation ? <InlineStack align="center">
                                <Text as="p">Amazon Recommendations</Text>
                            </InlineStack> : "Set Custom Value"}
                            // helpText={helpTextElement('others')}
                            id="others"
                            name="attributes"
                            checked={selectionType === 'others'}
                            onChange={handleChange}
                        />
                        <div className="selected__attribute-element">
                            {selectionType === 'others' && helpTextElement('others')}
                        </div>
                    </BlockStack>
                    <Divider />
                    <InlineStack gap={'100'} align="end" blockAlign="stretch">
                        {
                            history?.allowHistory && <Button onClick={() => {
                                history?.modalHandler?.setOpenHistory(true)
                            }}>
                                View History
                            </Button>
                        }
                        <Button disabled={disableOnsave()} onClick={resetPopover}>Reset</Button>
                        <Button variant="primary" onClick={onSave} disabled={disableOnsave()}>Done</Button>
                    </InlineStack>
                </BlockStack>
            </Popover>
        </>
    );
};



export const CedSelect = () => {
    return <></>
}

export const CedTextField = () => {
    return <></>
}