import React, { useEffect } from 'react';
import { Text } from '@shopify/polaris';

import './sideSheet.css'; // You can create a CSS file for styling
interface SideSheetProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string | React.ReactNode;
    subtitle?: string;
    children: React.ReactNode;
}

const SideSheet: React.FC<SideSheetProps> = ({ isOpen, onClose, title, subtitle, children }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <div className="side-sheet-container">
            {isOpen && <div role='none' className="overlay" onClick={onClose}></div>}
            <div className={`side-sheet ${isOpen ? 'open' : ''}`}>
                <div className='sheet-header-wrapper'>
                    <div className='sheet-headers'>
                        {title && <Text as='h3' fontWeight='bold' variant='headingMd'>{title}</Text>}
                        {subtitle && <Text as='p' tone='subdued'>{subtitle}</Text>}
                    </div>
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>

                <div className="sideSheet-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SideSheet;
