import { ColumnElementStructureI, BulkEditGridI } from "../../type";
import { Button, Tooltip, Text } from "@shopify/polaris";
import { checkForMutatedStates, extractEditedCol, trimTitle } from "../../helper";
import { HTMLElement } from "../HTMLElement";
import { _ACTIONS } from "../../context/actions";

export function useColumnRenderer(props: BulkEditGridI) {
    const { rowSelection, state, resetData } = props;
    const { globalState, dispatch } = state;

    function columnRenderer(columns: any[]) {
        let structure: any = [];
        columns?.forEach((elem, index) => {
            structure.push({
                ...elem,
                title: <div className="thead-tooltip">
                    {
                        typeof elem?.title === 'string' && elem?.title !== "Product Details" && elem?.title !== "SKU"? <Tooltip content={elem?.title}>
                            <Text as="h4" fontWeight="medium">
                                {trimTitle(elem?.title)}
                            </Text>
                        </Tooltip> :
                            <Text as="h4" fontWeight="medium">
                                {trimTitle(elem?.title)}
                            </Text>
                    }
                </div>,
                render: (t: any) => {
                    if (t === undefined) return columnElementStructure({
                        element: elem,
                        index
                    })
                    else return t
                },
                key: elem?.title
            })
        })
        return structure
    }

    const triggerOnDataChange = (triggeredFuntion: any, ...params: any) => {
        triggeredFuntion(...params);
    };

    function updatedHandleBulkEdit(key: string, value: string | number, index: number | undefined, currentSelection: any) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        tempRows?.forEach((row: any) => {
            if (rowSelection.selectedRowKeys?.includes(row['key']['value'])) {
                row[key]['displayValue'] = value;
                row[key]['isModified'] = true;
                row[key]['amazonRecommendation'] = currentSelection?.['amazonRecommendation'] ?? {}
                row[key]['currentSelection'] = currentSelection?.['currentSelection'] ?? {}
                row[key]['customText'] = currentSelection?.['customText'] ?? {}
                row[key]['shopifySelect'] = currentSelection?.['shopifySelect'] ?? {}
            }
        });

        dispatch({
            type: _ACTIONS.SET_ROW,
            payload: {
                rows: tempRows
            }
        })
    }

    const renderElement = (elem: any,dynamicClassName:any): any => {
        if (elem?.dataIndex === "ProductDetails" || elem.dataIndex === "sku")
            return showEditText(elem,dynamicClassName)
        else
            return <HTMLElement
                type='popover'
                payload={{
                    popover: {
                        hasAmazonRecommendation: elem?._field_data?.amazon_recommendation !== undefined,
                        amazonRecommendation: {
                            options: elem?._field_data?.amazon_recommendation ?? {},
                            value: ''
                        },
                        shopifySelect: {
                            options: globalState?.shopifyAttributes ?? [],
                            value: ''
                        },
                    },
                    columnName: elem?.title
                }}
                tableElementType='columns'
                onDataChange={(...params) => triggerOnDataChange(updatedHandleBulkEdit, ...params)}
            />
    }

    function showEditText(elem: any,dynamicClassName:any) {
        const uniqueIdentifier = elem.dataIndex;
        if (uniqueIdentifier === "ProductDetails") {
            return <div className={dynamicClassName}>Edit this row to update selected products</div>
        }
        else if (uniqueIdentifier === "sku" || uniqueIdentifier === "productTitle") {
            return <></>
        }
    }

    function columnElementStructure(props: ColumnElementStructureI) {
        const { element: elem, index } = props;
        const rowCount=globalState?.columns?.filter((elem: { visible: any; }) => elem.visible);
        const classConditions:any = {
            "description-text":true,
            "description-text-hasMoreRows": rowCount.length > 4,
            "description-text-hasOnlyFixedCol": rowCount.length < 3,
            "description-text-rows-oneScrollCol": rowCount.length === 3,
            "description-text-rows-twoScrollCol": rowCount.length === 4
        };
        
        const dynamicClassName = Object.keys(classConditions)
            .filter(className => classConditions[className])
            .join(' ');

        return <>
            {rowSelection.selectedRowKeys?.length >= 2 ?
                renderElement(elem,dynamicClassName)
                : <>
                    {elem?.dataIndex === "ProductDetails" ? <div className={dynamicClassName}><Text as='p'>Select two or more rows to enable bulk edit</Text></div> : <></>}
                </>}
            {
                (checkForMutatedStates(globalState?.commencedState, globalState?.rows) && rowSelection.selectedRowKeys?.length >= 2) && (elem?.dataIndex !== "ProductDetails") && (elem?.dataIndex !== "sku") &&
                <Button
                    key={elem?.title}
                    id='reset_btn'
                    disabled={extractEditedCol({
                        initialState: globalState?.commencedState,
                        modifiedState: globalState?.rows,
                        colIdentifier: elem?._field_data?.label ?? ''
                    })}

                    onClick={() => {
                        resetData({
                            index: index,
                            key: typeof elem?.title === 'object' ? elem?.dataIndex : elem?.title,
                            type: 'bulk',
                        })
                    }}
                    variant="plain">Reset</Button>
            }
        </>;
    }

    return { columnRenderer }
}