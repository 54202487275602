import { Navigate, useRoutes } from "react-router-dom";
import VariantBulkEdit from "./Component/VariantBulkEdit/VariantBulkEdit";
import BulkEditWrapper from "./Component/BulkEditWrapper";


export function AppRoutes(props: any): JSX.Element {
    const commonRoutes = [
        {
            path: "/",
            element: <Navigate to="/bulkedit" />
        },
        {
            path: "/bulkedit",
            element: <BulkEditWrapper />,
        },
        {
            path: "/variant-edit",
            element: <VariantBulkEdit />,
        },
    ];

    const element = useRoutes([
        ...commonRoutes
    ]);

    return <>{element}</>;
}