import { BulkEditGridI, PopoverPayloadI, RenderEditableCellI } from "../../type";
import { BlockStack, Button, InlineStack, Thumbnail, Text } from "@shopify/polaris";
import getClassNames from "../../Utility/getClassnames";
import { HTMLElement } from '../HTMLElement/index'
import { _DROPDOWN, _RESET } from "../../../../assets/iconsSvg";
import { _ACTIONS } from "../../context/actions";
import { isRowInMultipleSelection } from "../../helper/controller/rowController";

export function useRowRenderer(props: BulkEditGridI) {
    const { state, toggleEditAble, resetData, redirectToVariantEdit, rowSelection } = props;
    const { globalState, dispatch } = state;

    function rowsRenderer(rows: any[]) {
        let tempRowRenderer: any = [];
        Object.keys(rows ?? []).forEach((elem: any, index: number) => {
            tempRowRenderer.push({
                ProductDetails: <InlineStack gap={"200"} wrap={false} blockAlign='start' align='start'>
                    <div className={getClassNames({ "without-variant": rows[elem]?.editedData?.length < 2 })} style={{ width: '40px' }}>
                        <Thumbnail
                            source={rows[elem]?.main_image?.value ?? 'https://i.imgur.com/oUVyG2C.png"'}
                            size="small"
                            alt="Black choker necklace"
                        />
                    </div>
                    <BlockStack gap={"100"} align='start'>
                        <div className='product-name'>
                            <Text as="p">
                                {rows[elem]?.title?.value}
                            </Text>
                        </div>
                        {
                            rows[elem]?.editedData?.length > 1 &&
                            <div className='variant-name'>
                                <Button
                                    onClick={() => {
                                        redirectToVariantEdit?.(rows[elem]?.editedData)
                                        // setLoading(true)
                                        // setIsVariantGrid(true)
                                    }}
                                    variant="plain">
                                    {`${rows[elem]?.editedData?.length} Variants`}
                                </Button>
                            </div>
                        }
                    </BlockStack>
                </InlineStack>,
                // productTitle: rows[elem]?.title?.value,
                sku: rows[elem]?.source_product_id?.value ?? 'fsd5a6f46asf4as6f4a6sd54f',
                ...addColumnsWithElement(rows[elem], index),
                key: rows[elem]?.key?.value,
                index
            })
        });

        return [...tempRowRenderer];
    }

    function addColumnsWithElement(rows: any, parentRowIndex: number) {
        let temp: any = {};
        Object.keys(rows ?? []).forEach((colNameInRow: string, index: number) => {
            const rowProps: PopoverPayloadI = {
                ...rows[colNameInRow]
            };
            temp[colNameInRow] = (
                <div role='none'
                    key={colNameInRow + 'addColumnsWithElement'}
                    className={
                        getClassNames({
                            "selected_cell": index === props?.selectedCell?.selectedCell?.rowIndex && parentRowIndex === props?.selectedCell?.selectedCell?.columnIndex,
                            "rowdata__wrapper": true,
                            "rows__editedcell": rows[colNameInRow]?.['isModified'],
                        })}
                    onClick={() => {
                        const colIndex = getColumnIndex(colNameInRow);
                        let allowHandleSelection: boolean = isRowInMultipleSelection({
                            selectedRowKeys: rowSelection?.selectedRowKeys,
                            source_product_id: rows[colNameInRow]?.record?.source_product_id ?? ''
                        })
                        !allowHandleSelection &&
                            props?.handleCellClick({ colName: colNameInRow, colIndexInRow: index, parentRowIndex, rows, colIndex });
                    }}
                >
                    {
                        renderEditableCell({ rowProps, parentRowIndex, childIndex: index })
                    }
                </div>
            );
        });

        return temp;
    }

    /**
     * @description used to calculate the coordinates of the cell, for accessibilitys
     * @param columnName name of the column within the rows array
     * @returns index of the column
     */
    const getColumnIndex = (columnName: string): number => {
        const columns = globalState?.columns?.filter((elem: any) => elem?.visible);
        let colIndex: any = '';
        columns?.forEach((col: any, index: number) => {
            if (colIndex === '' && col['title'] === columnName) {
                colIndex = index;
            }
        });
        return colIndex;
    }

    const triggerOnDataChange = (triggeredFuntion: any, ...params: any) => {
        triggeredFuntion(...params);
    };

    function handleRowUpdate(key: string, value: string | number, index: number | undefined, currentSelection: any) {
        let tempRows: any = [...(globalState?.rows ?? [])];
        if (index !== undefined) {
            tempRows[index][key]['isModified'] = value !== globalState?.commencedState['rows'][index][key]['displayValue']
            tempRows[index][key]['displayValue'] = value;
            tempRows[index][key]['amazonRecommendation'] = currentSelection?.['amazonRecommendation'] ?? {}
            tempRows[index][key]['currentSelection'] = currentSelection?.['currentSelection'] ?? {}
            tempRows[index][key]['customText'] = currentSelection?.['customText'] ?? {}
            tempRows[index][key]['shopifySelect'] = currentSelection?.['shopifySelect'] ?? {}
            let tempHistory: any[] = [...tempRows[index][key]?.['history'] ?? []];
            tempHistory.push({ ...tempRows[index][key]['currentSelection'] });
            tempRows[index][key]['history'] = tempHistory;
            dispatch({
                type: _ACTIONS.SET_ROW,
                payload: {
                    rows: tempRows
                }
            })
        }
    }

    const renderEditableCell = (props: RenderEditableCellI) => {
        const { parentRowIndex, rowProps, childIndex } = props;
        let allowHandleSelection: boolean = isRowInMultipleSelection({
            selectedRowKeys: rowSelection?.selectedRowKeys,
            source_product_id: rowProps?.record?.source_product_id ?? ''
        });

        return rowProps?.clicked === 'editing' ?
            <div role='none' tabIndex={childIndex}>
                <HTMLElement
                    type='popover'
                    payload={{
                        popover: {
                            hasAmazonRecommendation: rowProps?.hasAmazonRecommendation,
                            amazonRecommendation: {
                                options: rowProps?.amazonRecommendation?.options,
                                value: rowProps?.amazonRecommendation?.value ?? undefined
                            },
                            shopifySelect: {
                                options: rowProps.shopifySelect.options ?? [],
                                value: rowProps.shopifySelect.value
                            },
                            customText: {
                                value: rowProps?.customText?.value
                            },
                            currentSelection: {
                                ...rowProps.currentSelection
                            },
                            displayValue: rowProps.displayValue,
                            clicked: rowProps?.clicked === 'editing'
                        },
                        columnName: rowProps?.columnName,
                        indexMatrix: {
                            row: parentRowIndex,
                            childIndex: childIndex
                        },
                    }}
                    tableElementType='rows'
                    onDataChange={(...params) => triggerOnDataChange(handleRowUpdate, ...params)}
                    toggleEditAble={toggleEditAble}
                />
            </div> :
            <InlineStack align='space-between' blockAlign='center' gap={'100'}>
                <div className="text-wrapper">
                    {rowProps.displayValue || '---'}
                </div>
                <InlineStack align='space-between' gap={'200'}>
                    {rowProps.isModified &&
                        <div role='none' className='reset-btn__wrapper' onClick={(e) => e.stopPropagation()}>
                            <Button
                                disabled={allowHandleSelection}
                                icon={_RESET}
                                onClick={() => resetData({ type: 'single', index: parentRowIndex, key: rowProps?.columnName })}
                                variant="plain" />
                        </div>
                    }
                    {_DROPDOWN}
                </InlineStack>
            </InlineStack>;
    };
    return { rowsRenderer }
}