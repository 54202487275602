import './App.css';
import { AppProvider, Frame } from '@shopify/polaris';
import "@shopify/polaris/build/esm/styles.css";

import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from './Routes'

function App() {
  return (
    <BrowserRouter >
      <AppProvider i18n={{}}>
        <div className="App">
          <Frame  >
            <AppRoutes />
          </Frame>
        </div>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
